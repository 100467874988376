import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';

import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import {OverlayModule} from '@angular/cdk/overlay';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MAT_DATE_LOCALE } from '@angular/material/core'
import { CodeEditorModule } from '@ngstack/code-editor';


@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    SpinnerComponent,
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot([]),
    BrowserModule,
    BrowserAnimationsModule,
    StepsModule,
    ToastModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    CodeEditorModule.forRoot({
      baseUrl: 'assets/monaco'
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' }
  ],
  exports:[
    MainLayoutComponent,
    CodeEditorModule,
  ]
})
export class SharedModule { }
