//Angular
import { Component, OnInit, SimpleChange, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router  } from '@angular/router';


//dependecies
import { ToastrService } from "ngx-toastr";

//Store
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

//Actions
// import { setStep } from "../../../@core/state/sidebar/sidebar.actions";

//States
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";
import { SurveyState } from '../../../../stores/survey/survey.reducer';

//services
import { DashboardService } from '../../../../services/dashboard/dashboard.service';

import { LandingChartsRequest, NPSChartsRequest } from 'src/app/models/global.request';

//Utils
import  Formatter  from 'src/app/utils/formatters';
import Utils from "src/app/utils/utils";

///Echarts
import { EChartsOption } from 'echarts';

import { Period } from "src/app/models/Period";
import { setMenuItem } from "src/app/stores/sidebar/sidebar.actions";
import { MenuItem } from 'src/app/models/MenuItem';
import { ROUTES } from "src/app/utils/constants";
import { setPeriodsCounters } from "src/app/stores/survey/survey.actions";


@Component({
  selector: "app-dash-landing",
  templateUrl: "./dash-landing.component.html",
  styleUrls: ["./dash-landing.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardLandingComponent  implements OnInit {

  questions: any;
  touchpointsGroupped: any;
  showModalKeys: boolean = false;

  sidebarState$: Observable<SidebarState>;
  surveyState$ : Observable<SurveyState>;
  dashboardData$: Observable<any[]> | undefined;
  currentMenuItemList!: MenuItem[];

  landingAnswers: any;
  currentSurvey: any ;
  periodList!: Period[] | null;
  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;
  isOnePeriod = false;
  //Variables
  surveyName!: string;

  periodsCounters: any = {
    views:[0,0],
    completed: [0,0],
  };
  utils!:Utils;
  chartOption!: EChartsOption;
  chartAccumulateOption!: EChartsOption;
  formatter!: Formatter;
  //Constructor
  constructor(
    private store: Store<{ sidebarState: SidebarState, surveyState: SurveyState  }>,
    public fb: FormBuilder,
    private dashboardService: DashboardService,
  ) {
    this.sidebarState$ = store.select("sidebarState");
    this.surveyState$ = store.select('surveyState');
    this.formatter = new Formatter();
    this.utils = new Utils();

  }

  // On Component Initialized
  ngOnInit(): void {
    this.sidebarState$.subscribe(sidebarS => {
      this.currentMenuItemList = sidebarS.menuItemList;

    });

    this.surveyState$.subscribe(surveyS => this.currentSurvey = surveyS.currentSurvey);
    this.surveyState$.subscribe(surveyS => {
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if( period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
        }
        if( period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
        }
      });
      if (this.periodOneStartDate.toISOString() === this.periodTwoStartDate.toISOString() && this.periodOneEndDate.toISOString() === this.periodTwoEndDate.toISOString()) {
        this.isOnePeriod = true;
      }

      const nameSurvey = surveyS?.currentSurvey?.customName ? surveyS?.currentSurvey?.customName : surveyS?.currentSurvey?.name;
      this.surveyName = nameSurvey || "";
      this.touchpointsGroupped = this.formatTouchPoints(surveyS.touchpoints);
      this.questions = surveyS.questions;
    });


    this.loadChartsData();
  }
  loadChartsData() {

    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }

    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }

    const currentState = localStorage?.getItem("state");
    const objState = JSON.parse(currentState?currentState :"");
    const token = objState?.authState?.user?.accessToken;

    const requestLandingCharts: LandingChartsRequest = {
      survey_id: this.currentSurvey?.id,
      periods:[periodOne, periodTwo],

    }

    this.dashboardService.getLandingCharts(requestLandingCharts).subscribe((data) =>
      {
      this.periodsCounters = data;
      this.chartOption = {
        tooltip: {
          trigger: 'axis',
          // eslint-disable-next-line func-names
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        title: {
          left: 'center',
          text: 'Completed Surveys Distribution'
        },

        xAxis: {
          type: 'time',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '30%']
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name: 'Completed Surveys',
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {},
            data: this.periodsCounters.allCompleted,
          }
        ]
      };
      let datesPeriods= [];
      datesPeriods.push(this.periodsCounters.accumulate[0].date1);
      datesPeriods.push(this.periodsCounters.accumulate[0].date2);
      datesPeriods.push(this.periodsCounters.accumulate[1].date1);
      datesPeriods.push(this.periodsCounters.accumulate[1].date2);
      datesPeriods = datesPeriods.filter( (value, index, array) =>{
        return array.indexOf(value) === index;
      });
      this.chartAccumulateOption = {
        tooltip: {
          trigger: 'item',
          formatter: '{c}'
        },
        xAxis: {
          type: 'category',
          data: datesPeriods
        },
        yAxis: {
          type: 'value',
        },
        series: [

          {
            name: 'Period 1',
            type: 'line',
            itemStyle: {color: '#533B83'},
            data: [[this.periodsCounters.accumulate[0].date1,this.periodsCounters.accumulate[0].count1],[this.periodsCounters.accumulate[0].date2,this.periodsCounters.accumulate[0].count2]]
          },
          {
            name: 'Period 2',
            type: 'line',
            itemStyle: {color: '#7FCFC2'},
            data: [[this.periodsCounters.accumulate[1].date1,this.periodsCounters.accumulate[1].count1],[this.periodsCounters.accumulate[1].date2,this.periodsCounters.accumulate[1].count2]]
          }
        ]
      };
        this.store.dispatch(setPeriodsCounters({ periodsCounters: this.periodsCounters }));
      });


      // this.dashboardService.getQuestionsOverviewCharts(request).subscribe((data) =>
      // {
      //   // const dataFormatted = this.formatter.formatResponseQuestionsOverview(data) as Question[]
      //   console.log( data);
      //   const result = data.filter((question: any) => question.question.question_id == 28);
      //   this.landingAnswers = this.formatter.formatResponseQuestionsOverview(result);
      //   console.log("data", this.landingAnswers)
      //   console.log( this.landingAnswers);

      // });
    }

  formatTouchPoints (data: any) {
    const touchpointsData: any[] = [];
    for (const key in data) {
      const questionsTemp = data[key];
      const questions = []
      for (let index = 0; index < questionsTemp.length; index++) {
          questions.push({
            domanda_id: questionsTemp[index].domanda_id,
            question_id: questionsTemp[index].question_id,
            description: questionsTemp[index].description,
          });
      }
      touchpointsData.push({
        group: key,
        questions: questions,
        rowspan: questions.length,
      })
    }

    return touchpointsData;
  }

  toggleModalKeys() {
    this.showModalKeys = !this.showModalKeys;
  }
}
