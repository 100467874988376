<div id="dash-text-boxes-wrapper">
  <div class="filter-question-wrapper">
    <form class="base--form-generic" [formGroup]="filterOpenTextBoxesForm">
      <div class="more-filters-wrapper">
        <div class="form-group">
          <div class="form-row">
            <div class="form-column" *ngIf="!isOnePeriod">
              <span class="filter-by">Choose Period:</span>
              <div class="select-wrapper">
                <select class="slim" formControlName="filterSelectedPeriodValue" (change)="onChangeSelectPeriod()" value="0">
                  <option value="" selected disabled hidden>Period 1</option>
                  <option value="0" >Period 1</option>
                  <option value="1" >Period 2</option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="form-column">
              <span class="filter-by">NPS filter:</span>
              <div class="select-wrapper">
                <select class="slim azure" formControlName="filterNPSValue" (change)="onChangeSelectNPSFilter()">
                  <option value="" selected disabled hidden>None</option>
                  <option *ngFor="let range of filterNPSValues" [ngValue]="range.value">
                    {{ range.label }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="form-column">
              <span class="filter-by">Aggregation filter:</span>
              <div class="select-wrapper">
                <select class="slim azure" formControlName="filterAggregationValue" (change)="onChangeSelectAggregationFilter()">
                  <option value="" selected disabled hidden>None</option>
                  <option *ngFor="let aggregation of filterAggregationValues" [ngValue]="aggregation">
                    {{ aggregation }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filters-wrapper">
        <div class="form-group">
          <div class="form-row">
            <div class="form-column">
              <div class="form-column">
                <span class="filter-by">Filter question ID:</span>
                <div class="select-wrapper">
                  <select class="slim" formControlName="filterQuestionId" (change)="onChangeAddNewFilter()">
                    <option value="" selected disabled hidden>Question ID</option>
                    <option *ngFor="let question of textAreaQuestions" [ngValue]="question">
                      {{ question }} - {{ getQuestionFromId(question) }}
                    </option>
                  </select>
                  <div class="select-icon slim">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group filter-container" *ngFor="let item  of selectedFiltersListForm.controls; let i = index">
          <div class="form-row">
          <div class="form-column">
            <span class="filter-by"></span>
            <div class="select-wrapper"
              [tippy] = "getLabelQuestionById(item.value.filterQuestionId)"
              [lazy]="true"
              >
              <select class="slim readonly"
                [attr.disabled]="'disabled'"
                [value]="item.value.filterQuestionId"
              >
                <option *ngFor="let question of filterQuestions" [ngValue]="question.id" [selected]="question.id === item.value.filterQuestionId">
                  {{ question.description }}
                </option>
              </select>

            </div>
            <div class="select-image-wrapper">
              <div class="image-dropdown"  >
                <div class="select-image slim readonly" >
                  <ng-container *ngIf="item.value.filterAnswerRangeStart !== null && item.value.filterAnswerRangeEnd !== null; else textImgFilter">
                    {{ item.value.filterAnswerRangeStart + '-' + item.value.filterAnswerRangeEnd }}
                  </ng-container>
                  <ng-template #textImgFilter>
                    {{filterAnswerValue ? getLabelAnswerById(item?.value?.filterQuestionId, item?.value?.filterAnswerId) : 'Select answer'}}
                  </ng-template>
                </div>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>


        <div class="form-group " *ngIf="selectedFiltersListForm.value.length<3 && showFiltersListFormVisible">
          <div class="form-row">
            <div class="form-column">
              <span class="filter-by">Filter by:</span>
              <div class="select-wrapper">
                <select class="slim" formControlName="filterQuestionValue" (change)="onChangeSelectQuestion()">
                  <option value="" selected disabled hidden>Question</option>
                  <option *ngFor="let question of filterQuestions" [ngValue]="question.id">
                    {{ question.description }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="select-image-wrapper">
                <div class="image-dropdown" *ngIf="(filterQuestionValue?.valid)" >
                  <div class="select-image slim" (click)="showOptionsContainer = !showOptionsContainer">
                    {{filterAnswerValue? getLabelAnswerById(filterQuestionValue?.value, filterAnswerValue.value ) : ' Select answer'}}
                  </div>
                  <div class="select-icon slim">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
                <div class="options-container" *ngIf="showOptionsContainer" [ngClass]="showOptionsContainer?'expanded':''">
                  <ng-container  *ngIf="filteredQuestionType === 'knob' || filteredQuestionType === 'slider'; else answerListWrapper">
                    <form class="answers-range-wrapper" [formGroup]="filterOpenTextBoxesForm">
                      <div class="answers-range">
                        <div class="interval-value">
                          Inizio intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeStart">
                        </div>
                        <div class="interval-value">
                          Fine intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeEnd">
                        </div>
                      </div>
                      <br>Minimo: {{ answersOptions[0].range_start }}
                      <br>Massimo: {{ answersOptions[0].range_end }}
                    </form>
                  </ng-container>
                  <ng-template #answerListWrapper>
                    <div class="answer-list-wrapper">
                      <div class="answer-list">
                        <div class="answer" [ngClass]="(filterAnswerValue?.value===(answer.id.toString()))?'selected': filterAnswerValue?.value !== '' ?'not-selected':''"  *ngFor="let answer of answersOptions" (click)="setAnswerOption(answer.id.toString())">
                          <div *ngIf="answer.urlImage; else textWrapper" class="image-wrapper" [tippy] = "answer.description">
                            <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.urlImage " alt="Answer Image" loading="lazy"/>
                          </div>
                          <ng-template #textWrapper>
                            <div class="description">
                              {{ answer.description }}
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="answer-controls">
                    <div (click)="onClickCloseOptionsContainer()" class="btn-cancel"> cancel </div>
                    <button (click)="onClickAddNewFilter()" *ngIf="selectedFiltersListForm.value.length<3" [disabled]="!validNewFilter(filteredQuestionType, answersOptions[0].range_start, answersOptions[0].range_end)" type="button" class="btn-ok" > Ok </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="error" *ngIf="filterQuestionValue?.invalid && (filterQuestionValue?.dirty ||filterQuestionValue?.touched)">
            <sup>*</sup>Please provide filter question
          </div>
        </div>
        <div class="form-group justify-content-end">
          <div class="form-row">
            <div class="form-column">
              <div class="btn-default slim"   (click)="onClickShowFiltersListForm()" *ngIf="selectedFiltersListForm.value.length<3 && selectedFiltersListForm.value.length>=1">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_509_2721)">
                    <path d="M9.65117 11.312C9.33584 11.312 9.09217 11.226 8.92017 11.054C8.74817 10.8677 8.66217 10.6168 8.66217 10.3015V6.7755H5.20067C4.89967 6.7755 4.66317 6.69667 4.49117 6.539C4.31917 6.367 4.23317 6.1305 4.23317 5.8295C4.23317 5.5285 4.31917 5.29917 4.49117 5.1415C4.66317 4.9695 4.89967 4.8835 5.20067 4.8835H8.66217V1.465C8.66217 1.14967 8.74817 0.905999 8.92017 0.733999C9.09217 0.561999 9.34301 0.476 9.67267 0.476C9.98801 0.476 10.2245 0.561999 10.3822 0.733999C10.5542 0.905999 10.6402 1.14967 10.6402 1.465V4.8835H14.1017C14.417 4.8835 14.6535 4.9695 14.8112 5.1415C14.9832 5.29917 15.0692 5.5285 15.0692 5.8295C15.0692 6.1305 14.9832 6.367 14.8112 6.539C14.6535 6.69667 14.417 6.7755 14.1017 6.7755H10.6402V10.3015C10.6402 10.6168 10.5542 10.8677 10.3822 11.054C10.2245 11.226 9.98084 11.312 9.65117 11.312Z" fill="white" />
                  </g>
                  <defs>
                    <filter id="filter0_d_509_2721" x="0.233154" y="0.476074" width="18.8359" height="18.8359" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_2721" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_2721" result="shape" />
                    </filter>
                  </defs>
                </svg> Add filter
              </div>
              <div class="btn-default slim" (click)="onClickRemoveLastFilter()" *ngIf="selectedFiltersListForm.value.length>0" >
                cancel
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="base--tpanel-periods-wrapper">
        <div class="period" [ngClass]="{'purple': filterSelectedPeriodValue?.value === '0' || filterSelectedPeriodValue?.value === ''}">
          <div class="left-col-wrapper">
            <div class="icon">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
              </svg>
            </div>
            <div class="info-wrapper">
              <div class="title">
                  PERIOD {{ isOnePeriod ? '' : '1' }}
              </div>
              <div class="dates-wrapper">
                <span>{{utils.formatDateToString(periodOneStartDate)}}</span>
                <span>{{utils.formatDateToString(periodOneEndDate)}}</span>
              </div>
            </div>
          </div>
          <div class="right-col-wrapper">
            <div class="top-wrapper">
              <span>Total <br> respondents</span>
            </div>
            <div class="bottom-wrapper">
              <span>{{this.periodsCounters.completed[0]}}</span>
            </div>
          </div>
        </div>
        <div class="period" [ngClass]="{'azure': filterSelectedPeriodValue?.value === '1'}" *ngIf="!isOnePeriod">
          <div class="left-col-wrapper">
            <div class="icon">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
              </svg>
            </div>
            <div class="info-wrapper">
              <div class="title">
                  PERIOD 2
              </div>
              <div class="dates-wrapper">
                <span>{{utils.formatDateToString(periodTwoStartDate)}}</span>
                <span>{{utils.formatDateToString(periodTwoEndDate)}}</span>
              </div>
            </div>
          </div>
          <div class="right-col-wrapper">
            <div class="top-wrapper">
              <span>Total <br> respondents</span>
            </div>
            <div class="bottom-wrapper">
              <span>{{this.periodsCounters.completed[1]}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="fields-wrapper justify-content-end">
        <div class="form-group">
          <div class="form-row">
            <div class="form-column">
              <div class="btn-default slim purple" (click)="toggleModalKeys()" >
                Show survey
              </div>
            </div>
            <div class="form-column">
              <div class="btn-default slim purple" (click)="toggleModalCloud()" >
                Show word cloud
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-0">
        <tr class="text-boxes-chart-wrapper col-12">
          <table *ngIf="this.openTextBoxesAnswers$?.length > 0">
            <tr>
              <th>
                Id Question
              </th>

              <th>
                  SUGGESTION<br/>
                  SCORE
              </th>
              <th>
                SATISFACTION<br/>
                SCORE
              </th>
              <th>
                TOUCHPOINT
              </th>
              <th *ngFor="let filter of selectedFiltersListForm.controls; let i = index">
                FILTER {{i + 1}}
              </th>
              <th>
                COMMENT
              </th>
            </tr>
            <tr class="" *ngFor="let answer of this.openTextBoxesAnswers$; let i = index">
              <td> <span [tippy]="getQuestionFromId(answer.domanda_id)">{{answer.domanda_id}}</span></td>
              <td>
                <div *ngIf="answer.nps?.risposta !== null; else elseNullImage">
                  <ng-container *ngIf="isNumeric(answer.nps?.risposta); else npsImage">
                    <div class="answer nps">{{ answer.nps?.risposta }}</div>
                  </ng-container>
                  <ng-template #npsImage>
                    <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.nps?.risposta"  [tippy] = "answer.nps?.testo_risposta"  alt="Answer Image" loading="lazy"/>
                  </ng-template>
                </div>
                <ng-template #elseNullImage>
                  <div>
                    <div class="empty-image-box">
                    </div>
                  </div>
                </ng-template>
              </td>
              <td>
                 <div *ngIf="answer.satisfaction?.risposta !== null; else elseNullImage">
                  <ng-container *ngIf="isNumeric(answer.satisfaction?.risposta); else satisfactionImage">
                    <div class="answer satisfaction">{{ answer.satisfaction?.risposta }}</div>
                  </ng-container>
                  <ng-template #satisfactionImage>
                    <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.satisfaction?.risposta"  [tippy] = "answer.satisfaction?.testo_risposta"  alt="Answer Image" loading="lazy"/>
                  </ng-template>
                </div>
                <ng-template #elseNullImage>
                  <div>
                    <div class="empty-image-box">
                    </div>
                  </div>
                </ng-template>
              </td>
              <td> {{answer.touch_point}}</td>
              <td *ngFor="let filter of selectedFiltersListForm.controls; let i = index">
                <div *ngIf="getValueOfFilterAnswer(answer.answers_filter, filter.value.filterQuestionId) !== null; else elseNullImage">
                  <img [src]="'https://epathoscdn.it/TEST/img/'+ getValueOfFilterAnswer(answer.answers_filter, filter.value.filterQuestionId)"  [tippy] = "answer.testo_risposta"  alt="Answer Image" loading="lazy"/>
                </div>
                <ng-template #elseNullImage>
                  <div>
                    <div class="empty-image-box">
                    </div>
                  </div>
                </ng-template>
              </td>
              <td> <p>
                {{(answer.showMore) ? answer.risposta : (answer.risposta | slice:0:130)}} <span *ngIf="!answer.showMore && (answer.risposta.length > 130)">...</span>
                <a href="javascript:;" *ngIf="!answer.showMore && (answer.risposta.length > 130) " (click)="answer.showMore=true">[Read more]</a>

              </p></td>
            </tr>
          </table>
          <div *ngIf="this.openTextBoxesAnswers$?.length > 0" class="form-actions d-flex justify-content-center m-5">
            <button class="btn-next" (click)="showMoreItems()">
              Show More
            </button>
          </div>
          <div style="display: flex; justify-content: center;" *ngIf="this.openTextBoxesAnswers$?.length === 0">
            <h3>Not comments found</h3>
          </div>
        </div>
    </form>
  </div>

  <ng-container *ngIf="showModalKeys" >
    <div class="modal-keys">
      <section>
          <div class="top-wrapper">
            <button class="modal-close"type="button" (click)="toggleModalKeys()">X</button>
          </div>
          <table>
            <thead>
              <tr>
                <th align="center">TOUCHPOINT</th>
                <th align="center">ID</th>
                <th align="center" style="width: 75%;">DOMANDA</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let question of questions" >
                <tr>
                  <td align="center">{{question.touch_point}}</td>
                  <td align="center" >{{question.domanda_id}}</td>
                  <td style="width: 70%;" align="center" >{{question.description}}</td>
                </tr>
              </ng-container>

            </tbody>

          </table>
      </section>
    </div>

  </ng-container>

  <ng-container *ngIf="showModalCloud" >
    <div class="modal-cloud">
      <section>
          <div class="top-wrapper purple">
            <div class="modal-title">Keywords cloud</div>
            <button class="modal-close"type="button" (click)="toggleModalCloud()">X</button>
          </div>
          <angular-tag-cloud
            [data]="cloudData"
            [width]="cloudOptions.width"
            [height]="cloudOptions.height"
            [overflow]="cloudOptions.overflow">
          </angular-tag-cloud>
      </section>
    </div>

  </ng-container>
</div>
