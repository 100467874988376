<div id="dash-aggregation-wrapper">
  <div class="filter-question-wrapper">
    <form class="base--form-generic" [formGroup]="filterAggregationForm">
      <div class="filters-wrapper">
        <div class="form-group filter-container" *ngFor="let item  of selectedFiltersListForm.controls; let i = index">
          <div class="form-row">
          <div class="form-column">
            <span class="filter-by"></span>
            <div class="select-wrapper"
              [tippy] = "getLabelQuestionById(item.value.filterQuestionId)"
              [lazy]="true"
              >
              <select class="slim readonly"
                [attr.disabled]="'disabled'"
                [value]="item.value.filterQuestionId"
              >
                <option *ngFor="let question of filterQuestions" [ngValue]="question.id" [selected]="question.id === item.value.filterQuestionId">
                  {{ question.description }}
                </option>
              </select>

            </div>
            <div class="select-image-wrapper">
              <div class="image-dropdown"  >
                <div class="select-image slim readonly" >
                  <ng-container *ngIf="item.value.filterAnswerRangeStart !== null && item.value.filterAnswerRangeEnd !== null; else textImgFilter">
                    {{ item.value.filterAnswerRangeStart + '-' + item.value.filterAnswerRangeEnd }}
                  </ng-container>
                  <ng-template #textImgFilter>
                    {{filterAnswerValue ? getLabelAnswerById(item?.value?.filterQuestionId, item?.value?.filterAnswerId) : 'Select answer'}}
                  </ng-template>
                </div>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="form-group " *ngIf="selectedFiltersListForm.value.length<3 && showFiltersListFormVisible">
          <div class="form-row">
            <div class="form-column">
              <span class="filter-by">Filter by:</span>
              <div class="select-wrapper">
                <select class="slim" formControlName="filterQuestionValue" (change)="onChangeSelectQuestion()">
                  <option value="" selected disabled hidden>Question</option>
                  <option *ngFor="let question of filterQuestions" [ngValue]="question.id">
                    {{ question.description }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="select-image-wrapper">
                <div class="image-dropdown" *ngIf="(filterQuestionValue?.valid)" >
                  <div class="select-image slim" (click)="showOptionsContainer = !showOptionsContainer">
                    {{filterAnswerValue? getLabelAnswerById(filterQuestionValue?.value, filterAnswerValue.value ) : ' Select answer'}}
                  </div>
                  <div class="select-icon slim">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
                <div class="options-container" *ngIf="showOptionsContainer" [ngClass]="showOptionsContainer?'expanded':''">
                  <ng-container  *ngIf="filteredQuestionType === 'knob' || filteredQuestionType === 'slider'; else answerListWrapper">
                    <form class="answers-range-wrapper" [formGroup]="filterAggregationForm">
                      <div class="answers-range">
                        <div class="interval-value">
                          Inizio intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeStart">
                        </div>
                        <div class="interval-value">
                          Fine intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeEnd">
                        </div>
                      </div>
                      <br>Minimo: {{ answersOptions[0].range_start }}
                      <br>Massimo: {{ answersOptions[0].range_end }}
                    </form>
                  </ng-container>
                  <ng-template #answerListWrapper>
                    <div class="answer-list-wrapper">
                      <div class="answer-list">
                        <div class="answer" [ngClass]="(filterAnswerValue?.value===(answer.id.toString()))?'selected': filterAnswerValue?.value !== '' ?'not-selected':''"  *ngFor="let answer of answersOptions" (click)="setAnswerOption(answer.id.toString())">
                          <div *ngIf="answer.urlImage; else textWrapper" class="image-wrapper" [tippy] = "answer.description">
                            <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.urlImage " alt="Answer Image" loading="lazy"/>
                          </div>
                          <ng-template #textWrapper>
                            <div class="description">
                              {{ answer.description }}
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="answer-controls">
                    <div (click)="onClickCloseOptionsContainer()" class="btn-cancel"> cancel </div>
                    <button (click)="onClickAddNewFilter()" *ngIf="selectedFiltersListForm.value.length<3" [disabled]="!validNewFilter(filteredQuestionType, answersOptions[0].range_start, answersOptions[0].range_end)" type="button" class="btn-ok" > Ok </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="error" *ngIf="filterQuestionValue?.invalid && (filterQuestionValue?.dirty ||filterQuestionValue?.touched)">
            <sup>*</sup>Please provide filter question
          </div>
        </div>
        <div class="form-group justify-content-end">
          <div class="form-row">
            <div class="form-column">
              <div class="btn-default slim"   (click)="onClickShowFiltersListForm()" *ngIf="selectedFiltersListForm.value.length<3 && selectedFiltersListForm.value.length>=1">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_509_2721)">
                    <path d="M9.65117 11.312C9.33584 11.312 9.09217 11.226 8.92017 11.054C8.74817 10.8677 8.66217 10.6168 8.66217 10.3015V6.7755H5.20067C4.89967 6.7755 4.66317 6.69667 4.49117 6.539C4.31917 6.367 4.23317 6.1305 4.23317 5.8295C4.23317 5.5285 4.31917 5.29917 4.49117 5.1415C4.66317 4.9695 4.89967 4.8835 5.20067 4.8835H8.66217V1.465C8.66217 1.14967 8.74817 0.905999 8.92017 0.733999C9.09217 0.561999 9.34301 0.476 9.67267 0.476C9.98801 0.476 10.2245 0.561999 10.3822 0.733999C10.5542 0.905999 10.6402 1.14967 10.6402 1.465V4.8835H14.1017C14.417 4.8835 14.6535 4.9695 14.8112 5.1415C14.9832 5.29917 15.0692 5.5285 15.0692 5.8295C15.0692 6.1305 14.9832 6.367 14.8112 6.539C14.6535 6.69667 14.417 6.7755 14.1017 6.7755H10.6402V10.3015C10.6402 10.6168 10.5542 10.8677 10.3822 11.054C10.2245 11.226 9.98084 11.312 9.65117 11.312Z" fill="white" />
                  </g>
                  <defs>
                    <filter id="filter0_d_509_2721" x="0.233154" y="0.476074" width="18.8359" height="18.8359" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_2721" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_2721" result="shape" />
                    </filter>
                  </defs>
                </svg> Add filter
              </div>
              <div class="btn-default slim" (click)="onClickRemoveLastFilter()" *ngIf="selectedFiltersListForm.value.length>0" >
                cancel
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-filters-wrapper justify-content-end">

        <div class="form-group" >
          <div class="form-row">
            <div class="form-column">
              <!-- <span class="filter-by">Select Aggregation Key:</span>
              <div class="select-wrapper">
                <select class="slim azure" formControlName="filterTouchPointValue" (change)="onChangeSelectTouchPoint()">
                  <option value="" selected disabled hidden>Touchpoints</option>
                  <option *ngFor="let touchPoint of touchPoints" [ngValue]="touchPoint.id">
                    {{ touchPoint.description }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div> -->
              <div class="btn-default slim purple" (click)="toggleModalKeys()" >
                Show survey
              </div>

            </div>
          </div>
        </div>
      </div>
      <div echarts [options]="chartOption" class="demo-chart"></div>
    </form>
  </div>
  <ng-container *ngIf="showModalKeys" >
    <div class="modal-keys">
      <section>
          <div class="top-wrapper">
            <button class="modal-close"type="button" (click)="toggleModalKeys()">X</button>
          </div>
          <table>
            <thead>
              <tr>
                <th align="center">TOUCHPOINT</th>
                <th align="center">ID</th>
                <th align="center" style="width: 75%;">DOMANDA</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let question of questions" >
                <tr>
                  <td align="center">{{question.touch_point}}</td>
                  <td align="center" >{{question.domanda_id}}</td>
                  <td style="width: 70%;" align="center" >{{question.description}}</td>
                </tr>
              </ng-container>

            </tbody>

          </table>
      </section>
    </div>

  </ng-container>
</div>
