//Angular
import { Component, OnInit, SimpleChange, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router  } from '@angular/router';

//Models
import { Period } from "src/app/models/Period";
import { Answer, AnswerRange } from "src/app/models/Answer";
import { Question } from 'src/app/models/Question';

//dependecies
import { ToastrService } from "ngx-toastr";
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';

//Store
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

//States
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";
import { SurveyState } from '../../../../stores/survey/survey.reducer';

//services
import { DashboardService } from '../../../../services/dashboard/dashboard.service';

//Utils
import  Utils  from 'src/app/utils/utils';
import  Formatter  from 'src/app/utils/formatters';

//request
import { OpenTextBoxesRequest } from "src/app/models/global.request";


//Htmlto image
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

@Component({
  selector: "app-dash-text-boxes",
  templateUrl: "./dash-text-boxes.component.html",
  styleUrls: ["./dash-text-boxes.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardTextBoxesComponent  implements OnInit {

  questions: any;
  touchpointsGroupped: any;
  showModalKeys: boolean = false;

  showModalCloud: boolean = false;
  cloudOptions: CloudOptions = {
    width: 1,
    height: 350,
    overflow: false,
  };
  cloudData: CloudData[] = [];

  textAreaQuestions: any;

  sidebarState$: Observable<SidebarState>;
  surveyState$ : Observable<SurveyState>;
  utils!:Utils;
  formatter!:Formatter;

  dashboardData$: Observable<any[]> | undefined;
  answersOptions !: AnswerRange[];
  filteredQuestionType = "";

  //Local Variables
  periodList!: Period[] | null;
  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;
  isOnePeriod = false;

  surveyName!: string;
  filterQuestions: any ;

  filterNPSValues = [
    {value:"detractors", label:"Detractors"},
    {value:"passives", label:"Passives"},
    {value:"promoters", label:"Promoters"},
  ]
  filterAggregationValues !: any;

  periodsCounters : any;
  currentSurvey: any ;
  openTextBoxesAnswers$!: any;

  npsAnswers: any;
 //Toggle
  showOptionsContainer !: boolean;
  showFiltersListFormVisible: boolean = true;
  selectedCrossQuestion!: Question;
  currentPeriodToggle!: number;

  //Pagination
  startPage : Number;
  paginationLimit:Number;

  showMore = false;
  //Constructor
  constructor(
    private store: Store<{ sidebarState: SidebarState, surveyState: SurveyState  }>,
    public fb: FormBuilder,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.sidebarState$ = store.select("sidebarState");
    this.surveyState$ = store.select('surveyState');
    this.utils = new Utils();
    this.formatter = new Formatter();
    this.startPage = 0;
    this.paginationLimit = 10;
    this.currentPeriodToggle = 0;
  }


  filterOpenTextBoxesForm = this.fb.group({
    filterQuestionValue:  [ "", [Validators.required]],
    filterQuestionType:  [ "", [Validators.required]],
    filterQuestionId: ["", [Validators.required]],
    filterAnswerValue: ["", [Validators.required]],
    filterAnswerRangeStart: ["", [Validators.required]],
    filterAnswerRangeEnd: ["", [Validators.required]],
    filterCrossQuestionValue: ["",[Validators.required]],
    filterNPSValue:["",[Validators.required]],
    filterSelectedPeriodValue:["",[Validators.required]],
    filterAggregationValue:["",[Validators.required]],
    selectedFiltersListForm: this.fb.array([],) ,
  });

  setfilterQuestionValue(value: any) {
    this.filterQuestionValue?.setValue(value, {
      onlySelf: true,
    });
  }
  setfilterQuestionId(value: any) {
    this.filterQuestionId?.setValue(value, {
      onlySelf: true,
    });
  }
  setfilterQuestionType(value: any) {
    this.filterQuestionType?.setValue(value, {
      onlySelf: true,
    });
  }
  setfilterCrossQuestionValue(value: any) {
    this.filterCrossQuestionValue?.setValue(value, {
      onlySelf: true,
    });
  }


  setFilterAnswerValue(value: any) {
    this.filterAnswerValue?.setValue(value, {
      onlySelf: true,
    });
  }
  setFilterRangeStart(value: any) {
    this.filterAnswerRangeStart?.setValue(value, {
      onlySelf: true,
    });
  }

  setFilterRangeEnd(value: any) {
    this.filterAnswerRangeEnd?.setValue(value, {
      onlySelf: true,
    });
  }

  get filterSelectedPeriodValue() {
    return this.filterOpenTextBoxesForm.get("filterSelectedPeriodValue");
  }
  get filterQuestionValue() {
    return this.filterOpenTextBoxesForm.get("filterQuestionValue");
  }
  get filterQuestionId() {
    return this.filterOpenTextBoxesForm.get("filterQuestionId");
  }
  get filterQuestionType() {
    return this.filterOpenTextBoxesForm.get("filterQuestionType");
  }
  get filterCrossQuestionValue() {
    return this.filterOpenTextBoxesForm.get("filterCrossQuestionValue");
  }
  get filterNPSValue() {
    return this.filterOpenTextBoxesForm.get("filterNPSValue");
  }
  get filterAggregationValue() {
    return this.filterOpenTextBoxesForm.get("filterAggregationValue");
  }

  get filterAnswerValue() {
    return this.filterOpenTextBoxesForm.get("filterAnswerValue");
  }
  get filterAnswerRangeStart() {
    return this.filterOpenTextBoxesForm.get("filterAnswerRangeStart");
  }

  get filterAnswerRangeEnd() {
    return this.filterOpenTextBoxesForm.get("filterAnswerRangeEnd");
  }

  get selectedFiltersListForm() : FormArray {
    return this.filterOpenTextBoxesForm.get("selectedFiltersListForm") as FormArray
  }

  setAnswerOption(idAnswer: string) {
    this.filterOpenTextBoxesForm.controls['filterAnswerValue'].setValue(idAnswer);
  }


  // On Component Initialized
  ngOnInit(): void {

    this.surveyState$.subscribe(surveyS => {
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if( period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
        }
        if( period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
        }
      });
      if (this.periodOneStartDate.toISOString() === this.periodTwoStartDate.toISOString() && this.periodOneEndDate.toISOString() === this.periodTwoEndDate.toISOString()) {
        this.isOnePeriod = true;
      }

      const nameSurvey = surveyS?.currentSurvey?.customName ? surveyS?.currentSurvey?.customName : surveyS?.currentSurvey?.name;
      this.surveyName = nameSurvey || "";
      this.currentSurvey = surveyS.currentSurvey
      this.filterQuestions = surveyS.filterQuestions;
      this.periodsCounters = surveyS.periodsCounters;
      this.filterAggregationValues = Object.keys(surveyS.touchpoints);
      this.touchpointsGroupped = this.formatTouchPoints(surveyS.touchpoints);
      this.questions = surveyS.questions;

      this.dashboardService.getTextAreaId({survey_id: this.currentSurvey.id}).subscribe(response => {
        this.textAreaQuestions = response.text_area_question_id;
      })
    });

    this.loadChartsData();
  }


  onChangeSelectQuestion() {
    if(this.filterQuestionValue?.value) {
      const selectedIndexQuestionId = parseInt(this.filterQuestionValue?.value, 10);
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id === selectedIndexQuestionId));
      this.answersOptions = tempSelectedQuestion.answers;
      this.filteredQuestionType = tempSelectedQuestion.type;
      this.filterQuestionType?.patchValue(tempSelectedQuestion.type);
    }

    // this.setFilterAnswerValue("");
  }


  onClickAddNewFilter() {
    const filterQuestionValue = this.filterQuestionValue?.value;
    const filterQuestionType = this.filterQuestionType?.value;
    const filterAnswerValue = this.filterAnswerValue?.value;
    const filterAnswerRangeStart = this.filterAnswerRangeStart?.value;
    const filterAnswerRangeEnd = this.filterAnswerRangeEnd?.value;

    if(filterQuestionValue && filterAnswerValue) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerValue});
    } else if (filterQuestionValue && filterAnswerRangeStart !== null && filterAnswerRangeEnd !== null) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerRangeStart, filterAnswerRangeEnd});
    }

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();

    this.showOptionsContainer = false;
    this.showFiltersListFormVisible = false;
    this.loadChartsData();
  }

  onChangeAddNewFilter() {
    this.loadChartsData();
  }

  onChangeSelectCrossQuestion() {
    if(this.filterCrossQuestionValue?.value) {
      const crossQuestionId = parseInt(this.filterCrossQuestionValue?.value, 10 );
      this.selectedCrossQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == crossQuestionId));

      this.loadChartsData();
    }

  }

  onClickCloseOptionsContainer() {
    this.showOptionsContainer = false;

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();
  }

  onClickShowFiltersListForm () {
    this.showFiltersListFormVisible = true;

  }

  onClickRemoveLastFilter() {
    if(this.selectedFiltersListForm.value.length == 1) {
      this.showFiltersListFormVisible = true;
    }
    const lastIndex = (this.selectedFiltersListForm.value.length) -1;
    this.selectedFiltersListForm.removeAt(lastIndex);

    if (lastIndex > 0) {
      this.filterQuestionValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionId);
      this.filterQuestionType?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionType);
      this.filterAnswerValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeStart?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeEnd?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
    } else {
      this.filterQuestionValue?.patchValue('');
      this.filterQuestionType?.patchValue('');
      this.filterAnswerValue?.patchValue('');
      this.filterAnswerRangeStart?.patchValue('');
      this.filterAnswerRangeEnd?.patchValue('');
      this.filterQuestionValue?.markAsPristine();
      this.filterQuestionValue?.markAsUntouched();
      this.filterQuestionType?.markAsPristine();
      this.filterQuestionType?.markAsUntouched();
      this.filterAnswerValue?.markAsPristine();
      this.filterAnswerValue?.markAsUntouched();
      this.filterAnswerRangeStart?.markAsPristine();
      this.filterAnswerRangeStart?.markAsUntouched();
      this.filterAnswerRangeEnd?.markAsPristine();
      this.filterAnswerRangeEnd?.markAsUntouched();
    }

    this.loadChartsData();
  }

  addSelectedFilterList(filter: any) {
    this.selectedFiltersListForm.push(this.newFilter(filter));
  }

  newFilter(filter: any): FormGroup {
    return this.fb.group({
      filterQuestionId:  filter.filterQuestionValue,
      filterQuestionType:  filter.filterQuestionType,
      filterAnswerId: filter.filterAnswerValue,
      filterAnswerRangeStart: filter.filterAnswerRangeStart,
      filterAnswerRangeEnd: filter.filterAnswerRangeEnd,
    })
  }



  getLabelQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.description
      }
    }

    return " "
  }

  getLabelAnswerById(filterQuestionId: any, filterAnswerId: any, allowEmpty: boolean = false) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.description
        }
      }
    }

    if (allowEmpty) {
      return "";
    }

    return "Select answer"
  }
  onClickDownloadImage() {
    const node = document.getElementById('dash-nps-wrapper') as HTMLElement;
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'nps-chart-image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  getDomandaIdQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.domanda_id
      }
    }

    return " "
  }

  getImageAnswerById(filterQuestionId: any, filterAnswerId: any) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.urlImage
        }
      }
    }

    return null
  }

  getFormatedCrossQuestion (crossQuestionId: any) {
    const crossQuestion = {
      question_id: crossQuestionId,
      domanda_id: this.getDomandaIdQuestionById(crossQuestionId),
    }

    return crossQuestion;
  }

  getFormatedFilters () {
    const formattedFilters: any = [];

    this.selectedFiltersListForm.controls.forEach( (filterElement) =>{
      const domandaId = this.getDomandaIdQuestionById(filterElement.value.filterQuestionId);
      const imagePath = this.getImageAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId);
      const description = this.getLabelAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId, true);
      const interval_start = filterElement.value.filterAnswerRangeStart;
      const interval_end = filterElement.value.filterAnswerRangeEnd;
      const question_type = filterElement.value.filterQuestionType;
      if (filterElement.value.filterQuestionId !== null) {
        formattedFilters.push({question_id: filterElement.value.filterQuestionId, domanda_id: domandaId, answer_image: imagePath, description: description, interval_start: interval_start, interval_end: interval_end, question_type: question_type })
      }
    });

    return formattedFilters;
  }

  getFormatedNpsRange () {
    const value = this.filterNPSValue?.value;
    switch (value) {
      case 'detractors':
        return [1,6];
      case 'passives':
        return [7,8];
      case 'promoters':
        return [9,10];
      default:
        return []
    }
  }

  getValueOfFilterAnswer(answers_filter: any, question_id: number) {
    const filterQuestion = this.filterQuestions.find((obj: any) => obj.id === question_id);
    const answerValue = answers_filter.find((obj: any) => obj.domanda_id === filterQuestion.domanda_id);

    return answerValue.risposta;
  }

  showMoreItems()
  {
    this.paginationLimit = Number(this.paginationLimit) + 10;
    this.loadChartsData();
  }
  showLessItems()
  {
    this.paginationLimit = Number(this.paginationLimit) - 10;
  }

  onChangeSelectPeriod () {
    const selectedPeriod = this.filterSelectedPeriodValue?.value as string;
    this.currentPeriodToggle = parseInt(selectedPeriod,10);
    this.paginationLimit = 10;
    this.loadChartsData();
  }

  onChangeSelectAggregationFilter() {
    this.loadChartsData();
    }
  onChangeSelectNPSFilter() {
    this.loadChartsData();
  }

  loadChartsData() {

    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }

    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }


    const request: OpenTextBoxesRequest = {
      survey_id: this.currentSurvey?.id,
      survey_name: this.currentSurvey?.name,
      period:  this.currentPeriodToggle == 0? periodOne : periodTwo,
      filterQuestions: this.getFormatedFilters() || [],
      filterQuestionId: this.filterQuestionId?.value ? [this.filterQuestionId?.value] : [],
      filterTouchpoint: this.filterAggregationValue?.value,
      filterNps: this.getFormatedNpsRange() || [],
      offset: 0,
      limit: this.paginationLimit as number,
    }
    this.dashboardService.getOpenTextBoxesCharts(request).subscribe((data) =>
    {
      this.openTextBoxesAnswers$ = this.formatter.formatResponseOpenTextBoxes(data);

      this.cloudData = this.formatter.formatWordCloud(data.word_cloud);
    });

  }

  formatTouchPoints (data: any) {
    const touchpointsData: any[] = [];
    for (const key in data) {
      const questionsTemp = data[key];
      const questions = []
      for (let index = 0; index < questionsTemp.length; index++) {
          questions.push({
            domanda_id: questionsTemp[index].domanda_id,
            question_id: questionsTemp[index].question_id,
            description: questionsTemp[index].description,
          });
      }
      touchpointsData.push({
        group: key,
        questions: questions,
        rowspan: questions.length,
      })
    }

    return touchpointsData;
  }

  toggleModalKeys() {
    this.showModalKeys = !this.showModalKeys;
  }

  getQuestionFromId(domanda_id: number) {
    const questionById = this.questions.filter((question: any) => question.domanda_id === domanda_id);

    return questionById[0].description;
  }

  toggleModalCloud() {
    this.showModalCloud = !this.showModalCloud;
  }

  validNewFilter(type: string, range_start: number, range_end: number) {
    if (type === 'knob' || type === 'slider') {
      return this.filterAnswerRangeStart?.valid &&
        this.filterAnswerRangeEnd?.valid &&
        this.filterAnswerRangeStart?.value !== null &&
        this.filterAnswerRangeEnd?.value !== null &&
        parseInt(this.filterAnswerRangeStart?.value) < parseInt(this.filterAnswerRangeEnd?.value) &&
        parseInt(this.filterAnswerRangeStart?.value) >= range_start &&
        parseInt(this.filterAnswerRangeStart?.value) <= range_end &&
        parseInt(this.filterAnswerRangeEnd?.value) >= range_start &&
        parseInt(this.filterAnswerRangeEnd?.value) <= range_end;
    } else {
      return this.filterAnswerValue?.valid;
    }
  }

  isNumeric(answer: any) {
    if (answer === '' || answer === null) {
      return false
    }

    return !isNaN(answer)
  }

}
