import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";
import { SurveyState } from '../../../../stores/survey/survey.reducer';

import { Period } from "src/app/models/Period";
import { setMenuItem } from "src/app/stores/sidebar/sidebar.actions";
import { MenuItem } from 'src/app/models/MenuItem';
import { ROUTES } from "src/app/utils/constants";
import { EmotionalRequest } from "src/app/models/global.request";
import { setPeriodsCounters } from "src/app/stores/survey/survey.actions";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";

import * as htmlToImage from 'html-to-image';
import { EChartsOption } from "echarts";
import Utils from "src/app/utils/utils";
import Formatter from "src/app/utils/formatters";

@Component({
  selector: "app-dash-emotional-overview",
  templateUrl: "./dash-emotional-overview.component.html",
  styleUrls: ["./dash-emotional-overview.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardEmotionalOverviewComponent implements OnInit {

  utils!: Utils;
  formatter!: Formatter;

  questions: any;
  currentSurvey: any;

  periodsCounters: any = {
    views: [0,0],
    completed: [0,0],
  };
  periodList!: Period[] | null;
  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;
  surveyName!: string;
  isOnePeriod = false;

  sidebarState$: Observable<SidebarState>;
  surveyState$ : Observable<SurveyState>;
  dashboardData$: Observable<any[]> | undefined;
  currentMenuItemList!: MenuItem[];

  emotionalMeter: any[] = [];
  emotionalOverview = [
    // {grey: 0, green: 0, orange: 0, red: 0, total: null, score: null},
    // {grey: 0, green: 0, orange: 0, red: 0, total: null, score: null}
    {green: 0, orange: 0, red: 0, total: null, score: null},
    {green: 0, orange: 0, red: 0, total: null, score: null}
  ];
  colors = {
    // grey: ['#CACCD2', '#A8AAB5', '#75767E'],
    red: ['#EB8484', '#EC2E21', '#C42423'],
    orange: ['#EBEBA5', '#EDEC53', '#EED954'],
    green: ['#86BA85', '#378F23', '#2F7B21'],
  };

  chartOverview1Option!: EChartsOption;
  chartOverview2Option!: EChartsOption;

  constructor(
    private store: Store<{ sidebarState: SidebarState, surveyState: SurveyState  }>,
    private dashboardService: DashboardService,
  ) {
    this.utils = new Utils();
    this.formatter = new Formatter();
    this.sidebarState$ = store.select("sidebarState");
    this.surveyState$ = store.select('surveyState');
  }

  ngOnInit(): void {
    this.sidebarState$.subscribe(sidebarS => {
      this.currentMenuItemList = sidebarS.menuItemList;
    });
    this.setDashboardMenuItem();

    this.surveyState$.subscribe(surveyS => this.currentSurvey = surveyS.currentSurvey);
    this.surveyState$.subscribe(surveyS => {
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if (period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
        }
        if (period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
        }
      });
      if (this.periodOneStartDate.toISOString() === this.periodTwoStartDate.toISOString() && this.periodOneEndDate.toISOString() === this.periodTwoEndDate.toISOString()) {
        this.isOnePeriod = true;
      }

      const nameSurvey = surveyS?.currentSurvey?.customName ? surveyS?.currentSurvey?.customName : surveyS?.currentSurvey?.name;
      this.surveyName = nameSurvey || "";
      this.questions = surveyS.questions;
    });

    this.loadChartsData();
  }

  loadChartsData() {
    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }

    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }

    const requestEmotionalCharts: EmotionalRequest = {
      survey_id: this.currentSurvey?.id,
      periods:[periodOne, periodTwo],
    }

    this.dashboardService.getEmotionalCharts(requestEmotionalCharts).subscribe((data) => {
      this.periodsCounters = {
        completed: data.completed
      };
      this.emotionalOverview = data.emotionalOverview;
      this.store.dispatch(setPeriodsCounters({ periodsCounters: this.periodsCounters }));

      this.chartOverview1Option = {
        backgroundColor: '#ffffff',
        height: '200px',
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)',
        },
        visualMap: {
          show: false,
          min: 0,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '90%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[0].grey,
              //   name: this.emotionalOverview[0].grey,
              //   itemStyle: {color: this.colors.grey[0]},
              // },
              {
                value: this.emotionalOverview[0].red,
                name: this.emotionalOverview[0].red,
                itemStyle: {color: this.colors.red[0]},
              },
              {
                value: this.emotionalOverview[0].orange,
                name: this.emotionalOverview[0].orange,
                itemStyle: {color: this.colors.orange[0]},
              },
              {
                value: this.emotionalOverview[0].green,
                name: this.emotionalOverview[0].green,
                itemStyle: {color: this.colors.green[0]},
              },
            ],
            label: {
              show: false,
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '50%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[0].grey,
              //   name: this.emotionalOverview[0].grey,
              //   itemStyle: {color: this.colors.grey[1]},
              // },
              {
                value: this.emotionalOverview[0].red,
                name: this.emotionalOverview[0].red,
                itemStyle: {color: this.colors.red[1]},
              },
              {
                value: this.emotionalOverview[0].orange,
                name: this.emotionalOverview[0].orange,
                itemStyle: {color: this.colors.orange[1]},
              },
              {
                value: this.emotionalOverview[0].green,
                name: this.emotionalOverview[0].green,
                itemStyle: {color: this.colors.green[1]},
              },
            ],
            label: {
              show: false,
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '20%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[0].grey,
              //   name: this.emotionalOverview[0].grey,
              //   itemStyle: {color: this.colors.grey[2]},
              // },
              {
                value: this.emotionalOverview[0].red,
                name: this.emotionalOverview[0].red,
                itemStyle: {color: this.colors.red[2]},
              },
              {
                value: this.emotionalOverview[0].orange,
                name: this.emotionalOverview[0].orange,
                itemStyle: {color: this.colors.orange[2]},
              },
              {
                value: this.emotionalOverview[0].green,
                name: this.emotionalOverview[0].green,
                itemStyle: {color: this.colors.green[2]},
              },
            ],
            label: {
              show: false,
            },
          },
        ]
      };

      this.chartOverview2Option = {
        backgroundColor: '#ffffff',
        height: '200px',
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)',
        },
        visualMap: {
          show: false,
          min: 0,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '90%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[1].grey,
              //   name: this.emotionalOverview[1].grey,
              //   itemStyle: {color: this.colors.grey[0]},
              // },
              {
                value: this.emotionalOverview[1].red,
                name: this.emotionalOverview[1].red,
                itemStyle: {color: this.colors.red[0]},
              },
              {
                value: this.emotionalOverview[1].orange,
                name: this.emotionalOverview[1].orange,
                itemStyle: {color: this.colors.orange[0]},
              },
              {
                value: this.emotionalOverview[1].green,
                name: this.emotionalOverview[1].green,
                itemStyle: {color: this.colors.green[0]},
              },
            ],
            label: {
              show: false,
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '50%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[1].grey,
              //   name: this.emotionalOverview[1].grey,
              //   itemStyle: {color: this.colors.grey[1]},
              // },
              {
                value: this.emotionalOverview[1].red,
                name: this.emotionalOverview[1].red,
                itemStyle: {color: this.colors.red[1]},
              },
              {
                value: this.emotionalOverview[1].orange,
                name: this.emotionalOverview[1].orange,
                itemStyle: {color: this.colors.orange[1]},
              },
              {
                value: this.emotionalOverview[1].green,
                name: this.emotionalOverview[1].green,
                itemStyle: {color: this.colors.green[1]},
              },
            ],
            label: {
              show: false,
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '20%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[1].grey,
              //   name: this.emotionalOverview[1].grey,
              //   itemStyle: {color: this.colors.grey[2]},
              // },
              {
                value: this.emotionalOverview[1].red,
                name: this.emotionalOverview[1].red,
                itemStyle: {color: this.colors.red[2]},
              },
              {
                value: this.emotionalOverview[1].orange,
                name: this.emotionalOverview[1].orange,
                itemStyle: {color: this.colors.orange[2]},
              },
              {
                value: this.emotionalOverview[1].green,
                name: this.emotionalOverview[1].green,
                itemStyle: {color: this.colors.green[2]},
              },
            ],
            label: {
              show: false,
            },
          },
        ]
      };

      this.emotionalMeter = this.formatter.formatResponseEmotionalMeter(data.emotionalMeter);
    });
  }

  setDashboardMenuItem() {
    const menuItem = new MenuItem(1,"Overview", ROUTES.ROUTE_EMOTIONAL_OVERVIEW, true);
    const tempMenuItemList =  this.currentMenuItemList.map(tMenuItem=> {
      tMenuItem = {...tMenuItem, isActive: false};

      return tMenuItem;
    });
    const tempMenuItem = Object.assign({},menuItem);;
    const index = tempMenuItemList.map(tMenuItem => tMenuItem.id).indexOf(tempMenuItem.id);
    tempMenuItem.isActive = true;
    tempMenuItemList[index] = tempMenuItem;
    this.store.dispatch(setMenuItem({ currentMenuItem:  tempMenuItem, menuItemList: tempMenuItemList }));
  }

  onClickDownloadImage(elementID: string) {
    const node = document.getElementById(elementID) as HTMLElement;
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = elementID + '-chart-image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

}
