
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router,NavigationEnd  } from '@angular/router';

import {MessageService} from 'primeng/api';

import packageJson from '../../../../../package.json';

//States
import { SidebarState } from '../../../stores/sidebar/sidebar.reducer';
import { AuthState } from '../../../stores/auth/authentication.reducer';

//Actions
import { toggle, setStep, setMenuItem } from "../../../stores/sidebar/sidebar.actions";
import { logout } from "../../../stores/auth/authentication.actions";
import { Step } from 'src/app/models/Step';
import { MenuItem } from 'src/app/models/MenuItem';
import Utils from 'src/app/utils/utils';

//Costants
import { ROUTES_DASHBOARD, ROUTES_HOMES } from '../../../utils/constants'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [MessageService]

})
export class SidebarComponent implements OnInit {

  ROUTES_DASHBOARD = ROUTES_DASHBOARD;

  version = packageJson.version;

  sidebarState$: Observable<SidebarState>;
  authState$: Observable<AuthState>;
  currentUrl: string | undefined;
  bgColor : string | undefined;
  isDashboardMenu : string | undefined;

  utils!: Utils;
  currentYear: number = new Date().getFullYear();

  currentStepList!: Step[];
  currentStep : Step | undefined;

  currentMenuItemList!: MenuItem[];
  currentMenuItem : MenuItem | undefined;
  constructor(private router: Router, private messageService: MessageService, private store: Store<{ sidebarState: SidebarState, authState: AuthState }>) {
    this.sidebarState$ = store.select('sidebarState');
    this.authState$ = store.select('authState');
  }
  ngOnInit(): void {
    this.sidebarState$.subscribe(sidebarS => {
      this.currentStepList = sidebarS.stepList;
      this.currentMenuItemList = sidebarS.menuItemList;

    });

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          this.changeSidebarTheme(event.url);
        }
      });

  }

  setActiveStep(step: Step) {
    const tempStepsList =  this.currentStepList.map(tStep=> {
      tStep = {...tStep, isActive: false};

      return tStep;
    });
    const tempStep = Object.assign({},step);;
    const index = tempStepsList.map(tStep => tStep.id).indexOf(tempStep.id);
    tempStep.isActive = true;
    tempStepsList[index] = tempStep;
    this.store.dispatch(setStep({ currentStep:  tempStep, stepList: tempStepsList }));

  }
  setActiveMenuItem(menuItem: MenuItem) {
    const tempMenuItemList =  this.currentMenuItemList.map(tMenuItem=> {
      tMenuItem = {...tMenuItem, isActive: false};

      return tMenuItem;
    });
    const tempMenuItem = Object.assign({},menuItem);;
    const index = tempMenuItemList.map(tMenuItem => tMenuItem.id).indexOf(tempMenuItem.id);
    tempMenuItem.isActive = true;
    tempMenuItemList[index] = tempMenuItem;
    this.store.dispatch(setMenuItem({ currentMenuItem:  tempMenuItem, menuItemList: tempMenuItemList }));

  }
  changeSidebarTheme( currentUrl : string) {
      this.isDashboardMenu = "";
      this.bgColor = 'bg-grey';
      if( ROUTES_DASHBOARD.includes(currentUrl)) {
        this.isDashboardMenu ='dashboard-menu';
        this.bgColor = 'bg-grey';
      }
      if( ROUTES_HOMES.includes(currentUrl) || currentUrl.includes('/confirm-password')) {
        this.isDashboardMenu = "";
        this.bgColor = 'bg-default';
      }

  }

  toggle() {
    this.store.dispatch(toggle());
  }
  logout() {
    this.store.dispatch(logout());
  }
}
