import { Answer, AnswerRange } from 'src/app/models/Answer';
import { Question } from 'src/app/models/Question';
//Angular
import { Component, OnInit, SimpleChange, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router  } from '@angular/router';

//Table
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

//dependecies
import { ToastrService } from "ngx-toastr";
// import { ImageCroppedEvent } from 'ngx-image-cropper';

//Store
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { animate, state, style, transition, trigger } from '@angular/animations';

//Actions
// import { setStep } from "../../../@core/state/sidebar/sidebar.actions";

//States
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";
import { SurveyState } from '../../../../stores/survey/survey.reducer';

//Utils
import  Utils  from 'src/app/utils/utils';
import  Formatter  from 'src/app/utils/formatters';


//services
import { DashboardService } from '../../../../services/dashboard/dashboard.service';
import { QuestionsOverviewChartsRequest, QuestionsOverviewCrossTabChartsRequest } from 'src/app/models/global.request';

//models
import { Period } from 'src/app/models/Period';


//Htmlto image
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';


interface selectDropdownItemMt{
  value: string;
  viewValue: string;
}


@Component({
  selector: "app-dash-questions-overview",
  templateUrl: "./dash-questions-overview.component.html",
  styleUrls: ["./dash-questions-overview.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DashboardQuestionsOverviewComponent  implements OnInit {



  sidebarState$: Observable<SidebarState>;
  surveyState$ : Observable<SurveyState>;
  utils!:Utils;
  formatter!:Formatter;

  dashboardData$: Observable<any[]> | undefined;
  answersOptions!: AnswerRange[];
  filteredQuestionType = "";
  // displayedSourceColumns = ['id', 'description','questionType','periodOneDistribution','periodOneThreat','periodTwoDistribution','periodTwoThreat','trend'];
  displayedSourceColumns = ['id', 'description','periodOneDistribution','periodOneThreat','periodTwoDistribution','periodTwoThreat','trend'];
  columnsToDisplayWithExpand = [...this.displayedSourceColumns, 'expand'];
  expandedElement: any | null;

  dataSourceQuestionsOverview!: MatTableDataSource<Question>;

  periodList!: Period[] | null;
  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;
  isOnePeriod = false;

  surveyName!: string;
  filterQuestions: any ;
  crossQuestions: any;
  periodsCounters : any;
  currentSurvey: any ;
  dataCrossTab: any;

  questions: any;
  touchpointsGroupped: any;

  //Toggle
  showOptionsContainer !: boolean;
  showFiltersListFormVisible: boolean = true;
  selectedCrossQuestion!: Question;
  showModalKeys: boolean = false;

  private paginatorSource!: MatPaginator;
  private sortSource!: MatSort;

  @ViewChild(MatSort, {
    static: false
  }) set matSortSource(ms: MatSort) {
    this.sortSource = ms;
  }
  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorSource(
    mp: MatPaginator
  ) {
    this.paginatorSource = mp;
  }

  // questionTypeList: selectDropdownItemMt[] = [
  //   {value: 'open', viewValue: 'Aperta'},
  //   {value: 'close', viewValue: 'Chiusa'},
  //   {value: 'multiple', viewValue: 'Multipla'},
  //   {value: 'slider', viewValue: 'Slider'},
  //   {value: 'knob', viewValue: 'Knob'},
  // ];
  //Constructor
  constructor(
    private store: Store<{ sidebarState: SidebarState, surveyState: SurveyState  }>,
    public fb: FormBuilder,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.sidebarState$ = store.select("sidebarState");
    this.surveyState$ = store.select('surveyState');
    this.utils = new Utils();
    this.formatter = new Formatter();
  }

  filterQuestionsOverviewForm = this.fb.group({
    filterQuestionValue:  [ "", [Validators.required]],
    filterQuestionType:  [ "", [Validators.required]],
    filterAnswerValue: ["", [Validators.required]],
    filterAnswerRangeStart: ["", [Validators.required]],
    filterAnswerRangeEnd: ["", [Validators.required]],
    filterCrossQuestionValue: ["",[Validators.required]],
    selectedFiltersListForm: this.fb.array([]),
  });

  setfilterQuestionValue(value: any) {
    this.filterQuestionValue?.setValue(value, {
      onlySelf: true,
    });
  }
  setfilterQuestionType(value: any) {
    this.filterQuestionType?.setValue(value, {
      onlySelf: true,
    });
  }
  setfilterCrossQuestionValue(value: any) {
    this.filterCrossQuestionValue?.setValue(value, {
      onlySelf: true,
    });
  }


  setFilterAnswerValue(value: any) {
    this.filterAnswerValue?.setValue(value, {
      onlySelf: true,
    });
  }

  setFilterRangeStart(value: any) {
    this.filterAnswerRangeStart?.setValue(value, {
      onlySelf: true,
    });
  }

  setFilterRangeEnd(value: any) {
    this.filterAnswerRangeEnd?.setValue(value, {
      onlySelf: true,
    });
  }

  get filterQuestionValue() {
    return this.filterQuestionsOverviewForm.get("filterQuestionValue");
  }
  get filterQuestionType() {
    return this.filterQuestionsOverviewForm.get("filterQuestionType");
  }
  get filterCrossQuestionValue() {
    return this.filterQuestionsOverviewForm.get("filterCrossQuestionValue");
  }

  get filterAnswerValue() {
    return this.filterQuestionsOverviewForm.get("filterAnswerValue");
  }
  get filterAnswerRangeStart() {
    return this.filterQuestionsOverviewForm.get("filterAnswerRangeStart");
  }

  get filterAnswerRangeEnd() {
    return this.filterQuestionsOverviewForm.get("filterAnswerRangeEnd");
  }

  get selectedFiltersListForm() : FormArray {
    return this.filterQuestionsOverviewForm.get("selectedFiltersListForm") as FormArray
  }
  // On Component Initialized
  ngOnInit(): void {

    this.surveyState$.subscribe(surveyS => {
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if( period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
        }
        if( period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
        }
      });
      if (this.periodOneStartDate.toISOString() === this.periodTwoStartDate.toISOString() && this.periodOneEndDate.toISOString() === this.periodTwoEndDate.toISOString()) {
        this.isOnePeriod = true;
        this.displayedSourceColumns = ['id', 'description','periodOneDistribution','periodOneThreat'];
        this.columnsToDisplayWithExpand = [...this.displayedSourceColumns, 'expand'];
      }

      const nameSurvey = surveyS?.currentSurvey?.customName ? surveyS?.currentSurvey?.customName : surveyS?.currentSurvey?.name;
      this.surveyName = nameSurvey || "";
      this.currentSurvey = surveyS.currentSurvey
      this.filterQuestions = surveyS.filterQuestions;
      this.periodsCounters = surveyS.periodsCounters;
      this.touchpointsGroupped = this.formatTouchPoints(surveyS.touchpoints);
      this.questions = surveyS.questions;
    });

    this.loadChartsData();
  }

  // ngAfterViewInit() {
  //   if(this.paginatorSource)
  //   this.dataSourceQuestionsOverview.paginator = this.paginatorSource;
  //   if(this.sortSource)
  //   this.dataSourceQuestionsOverview.sort = this.sortSource;
  // }

  onChangeSelectQuestion() {
    if(this.filterQuestionValue?.value) {
      const selectedIndexQuestionId = parseInt(this.filterQuestionValue?.value, 10);
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id === selectedIndexQuestionId));
      this.answersOptions = tempSelectedQuestion.answers;
      this.filteredQuestionType = tempSelectedQuestion.type;
      this.filterQuestionType?.patchValue(tempSelectedQuestion.type);
    }

    // this.setFilterAnswerValue("");
  }

  setAnswerOption(idAnswer: string) {
    this.filterQuestionsOverviewForm.controls['filterAnswerValue'].setValue(idAnswer);

  }

  onClickAddNewFilter() {
    const filterQuestionValue = this.filterQuestionValue?.value;
    const filterQuestionType = this.filterQuestionType?.value;
    const filterAnswerValue = this.filterAnswerValue?.value;
    const filterAnswerRangeStart = this.filterAnswerRangeStart?.value;
    const filterAnswerRangeEnd = this.filterAnswerRangeEnd?.value;

    if(filterQuestionValue && filterAnswerValue) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerValue});
    } else if (filterQuestionValue && filterAnswerRangeStart !== null && filterAnswerRangeEnd !== null) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerRangeStart, filterAnswerRangeEnd});
    }

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();

    this.showOptionsContainer = false;
    this.showFiltersListFormVisible = false;
    this.loadChartsData();
  }

  onChangeSelectCrossQuestion(expandedQuestion: any) {
      this.loadCrossTabChartsData(expandedQuestion);
  }

  onClickCloseOptionsContainer() {
    this.showOptionsContainer = false;

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();
  }

  onClickShowFiltersListForm () {
    this.showFiltersListFormVisible = true;

  }

  onClickRemoveLastFilter() {
    if(this.selectedFiltersListForm.value.length == 1) {
      this.showFiltersListFormVisible = true;
    }
    const lastIndex = (this.selectedFiltersListForm.value.length) -1;
    this.selectedFiltersListForm.removeAt(lastIndex);

    if (lastIndex > 0) {
      this.filterQuestionValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionId);
      this.filterQuestionType?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionType);
      this.filterAnswerValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeStart?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeEnd?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
    } else {
      this.filterQuestionValue?.patchValue('');
      this.filterQuestionType?.patchValue('');
      this.filterAnswerValue?.patchValue('');
      this.filterAnswerRangeStart?.patchValue('');
      this.filterAnswerRangeEnd?.patchValue('');
      this.filterQuestionValue?.markAsPristine();
      this.filterQuestionValue?.markAsUntouched();
      this.filterQuestionType?.markAsPristine();
      this.filterQuestionType?.markAsUntouched();
      this.filterAnswerValue?.markAsPristine();
      this.filterAnswerValue?.markAsUntouched();
      this.filterAnswerRangeStart?.markAsPristine();
      this.filterAnswerRangeStart?.markAsUntouched();
      this.filterAnswerRangeEnd?.markAsPristine();
      this.filterAnswerRangeEnd?.markAsUntouched();
    }

    this.loadChartsData();
  }

  addSelectedFilterList(filter: any) {
    this.selectedFiltersListForm.push(this.newFilter(filter));
  }

  newFilter(filter: any): FormGroup {
    return this.fb.group({
      filterQuestionId:  filter.filterQuestionValue,
      filterQuestionType:  filter.filterQuestionType,
      filterAnswerId: filter.filterAnswerValue,
      filterAnswerRangeStart: filter.filterAnswerRangeStart,
      filterAnswerRangeEnd: filter.filterAnswerRangeEnd,
    })
  }

  // setPaginationAndSortSourceQuestions() {
  //   this.dataSourceQuestionsOverview.paginator = this.paginatorSource;
  //   this.dataSourceQuestionsOverview.sort = this.sortSource;
  // }

  applyFilterForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceQuestionsOverview.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceQuestionsOverview.paginator) {
      this.dataSourceQuestionsOverview.paginator.firstPage();
    }
  }

  returnPropertyValueIfExist(propertyName: string, question: Question) {

    const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == question.id));

    if(tempSelectedQuestion) {
      const propertyValue = tempSelectedQuestion[propertyName];

      return propertyValue
    }

    return null
  }

  getLabelQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.description
      }
    }

    return " "
  }
  getDomandaIdQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.domanda_id
      }
    }

    return " "
  }

  getLabelAnswerById(filterQuestionId: any, filterAnswerId: any, allowEmpty: boolean = false) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.description
        }
      }
    }

    if (allowEmpty) {
      return "";
    }

    return "Select answer"
  }

  getImageAnswerById(filterQuestionId: any, filterAnswerId: any) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.urlImage
        }
      }
    }

    return null
  }


  getThreatClass(threats: any, color: string) {
    let isPresentColor= false;
    isPresentColor = threats.includes(color);

    return isPresentColor
  }


  getThreatDirection(threatsOne: any,threatsTwo: any): string {
    let direction ='equal'
    //first green
    if(threatsOne.includes('red')) {
      if(threatsTwo.includes('red')) {
        direction='equal';
      }
      if(threatsTwo.includes('orange')) {
        direction='up';
      }
      if(threatsTwo.includes('green')) {
        direction='up';
      }
    }

    if(threatsOne.includes('orange')) {
      if(threatsTwo.includes('red')) {
        direction='down';
      }
      if(threatsTwo.includes('orange')) {
        direction='equal';
      }
      if(threatsTwo.includes('green')) {
        direction='up';
      }
    }

    if(threatsOne.includes('green')) {
      if(threatsTwo.includes('red')) {
        direction='down';
      }
      if(threatsTwo.includes('orange')) {
        direction='down';
      }
      if(threatsTwo.includes('green')) {
        direction='equal';
      }
    }

    return direction;
  }

  toggleExpandedElementRow(element: any) {
    this.setfilterCrossQuestionValue("");
  }

  onClickDownloadImage(id: any) {

    // const question = document.getElementById('questions-overview-question-export-'+id) as HTMLElement;
    const answers = document.getElementById('questions-overview-answers-export-'+id) as HTMLElement;

    htmlToImage.toPng(answers)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'questions-overview-export-'+id+'.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }
  getFormatedFilters () {
    const formattedFilters: any = [];

    this.selectedFiltersListForm.controls.forEach( (filterElement) =>{
      const domandaId = this.getDomandaIdQuestionById(filterElement.value.filterQuestionId);
      const imagePath = this.getImageAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId);
      const description = this.getLabelAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId, true);
      const interval_start = filterElement.value.filterAnswerRangeStart;
      const interval_end = filterElement.value.filterAnswerRangeEnd;
      const question_type = filterElement.value.filterQuestionType;
      formattedFilters.push({question_id: filterElement.value.filterQuestionId, domanda_id: domandaId, answer_image: imagePath, description: description, interval_start: interval_start, interval_end: interval_end, question_type: question_type })
    });

    return formattedFilters;
  }

  getFormatedCurrentQuestion (expandedQuestion: any) {
    const currentQuestion = {
      question_id: expandedQuestion.id,
      domanda_id: expandedQuestion.domanda_id,
    }

    return currentQuestion;
  }

  getFormatedCrossQuestion (crossQuestionId: any) {
    const crossQuestion = {
      question_id: crossQuestionId,
      domanda_id: this.getDomandaIdQuestionById(crossQuestionId),
    }

    return crossQuestion;
  }

  loadChartsData() {

    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }

    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }

    const request: QuestionsOverviewChartsRequest = {
      survey_id: this.currentSurvey?.id,
      periods:[periodOne, periodTwo],
      filterQuestions: this.getFormatedFilters() || [],
      filterCrossQuestion: this.selectedCrossQuestion as Question,
    }

    this.dashboardService.getQuestionsOverviewCharts(request).subscribe((data) =>
      {
        const dataFormatted = this.formatter.formatResponseQuestionsOverview(data) as Question[]
        this.dataSourceQuestionsOverview = new MatTableDataSource(dataFormatted);
        this.crossQuestions = dataFormatted;

      });
  }


  loadCrossTabChartsData(expandedQuestion: any ) {

    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }

    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }
    const request: QuestionsOverviewCrossTabChartsRequest = {
      survey_id: this.currentSurvey?.id,
      periods:[periodOne, periodTwo],
      filterQuestions: this.getFormatedFilters() || [],
      currentQuestion: this.getFormatedCurrentQuestion(expandedQuestion),
      crossQuestion: this.getFormatedCrossQuestion(this.filterCrossQuestionValue?.value),
    }

    this.dashboardService.getQuestionsOverviewCrossTabCharts(request).subscribe((data) =>
    {
      this.dataCrossTab =  this.formatter.formatResponseCrossTab(data);
    });
  }

  formatTouchPoints (data: any) {
    const touchpointsData: any[] = [];
    for (const key in data) {
      const questionsTemp = data[key];
      const questions = []
      for (let index = 0; index < questionsTemp.length; index++) {
          questions.push({
            domanda_id: questionsTemp[index].domanda_id,
            question_id: questionsTemp[index].question_id,
            description: questionsTemp[index].description,
          });
      }
      touchpointsData.push({
        group: key,
        questions: questions,
        rowspan: questions.length,
      })
    }

    return touchpointsData;
  }

  toggleModalKeys() {
    this.showModalKeys = !this.showModalKeys;
  }

  validNewFilter(type: string, range_start: number, range_end: number) {
    if (type === 'knob' || type === 'slider') {
      return this.filterAnswerRangeStart?.valid &&
        this.filterAnswerRangeEnd?.valid &&
        this.filterAnswerRangeStart?.value !== null &&
        this.filterAnswerRangeEnd?.value !== null &&
        parseInt(this.filterAnswerRangeStart?.value) < parseInt(this.filterAnswerRangeEnd?.value) &&
        parseInt(this.filterAnswerRangeStart?.value) >= range_start &&
        parseInt(this.filterAnswerRangeStart?.value) <= range_end &&
        parseInt(this.filterAnswerRangeEnd?.value) >= range_start &&
        parseInt(this.filterAnswerRangeEnd?.value) <= range_end;
    } else {
      return this.filterAnswerValue?.valid;
    }
  }

}
