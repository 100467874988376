<div id="select-survey" *ngIf="(surveyState$|async); let surveyState;">
  <ng-container *ngIf="(navbarState$ | async); let navbarState;">
    <ng-container *ngIf="navbarState.selectContentType === 'survey'">
      <div class="last-survey-wrapper" >
        <div class="top-content">
          <span class="title">
            The last surveys published
          </span>
          <div class="show-others" (click)="paginationLastSurveysLimit = paginationLastSurveysLimit + 4; getLastSurveyList ();">
            <span>Show others</span>
            <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.5 1L3.5 7M6 4.375L3.5 7L1 4.375" stroke="#A8AAB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
        </div>

        <div *ngIf="lastSurveyList && lastSurveyList.length > 0; else emptySurveyList">
          <div class="survey-list">
            <div class="survey-item" *ngFor="let survey of lastSurveyList; let i = index">
              <div class="survey-content" [ngClass]="[(survey?.isSelected)? 'selected' : '']">
                <div class="image-wrapper" (click)="selectSurveyItem(survey)">
                  <div class="image-controls">
                      <svg class="icon-menu" width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" fill="white"/>
                        <circle cx="2" cy="8" r="2" fill="white"/>
                        <circle cx="2" cy="14" r="2" fill="white"/>
                      </svg>
                      <svg *ngIf="survey?.isNew"  class="icon-new" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1274_10153)">
                          <circle cx="38" cy="38" r="24" fill="#75C478"/>
                        </g>
                        <path d="M29.3575 33.54H31.4215V42H29.7895L25.9255 37.164V42H23.8735V33.54H25.4815L29.3575 38.376V33.54ZM32.9555 42V33.54H38.9675V35.244H35.1035V36.852H38.7275V38.544H35.1035V40.296H38.9675V42H32.9555ZM50.6494 33.54H52.7374L49.7134 42H47.9614L46.1734 36.924L44.3614 42H42.5974L39.5854 33.54H41.8294L43.5814 38.904L45.4774 33.54H47.0014L48.8134 39L50.6494 33.54Z" fill="white"/>
                        <defs>
                          <filter id="filter0_d_1274_10153" x="0" y="0" width="76" height="76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="7"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1274_10153"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1274_10153" result="shape"/>
                          </filter>
                        </defs>
                      </svg>
                  </div>
                  <img [src]="this.sanitizer.bypassSecurityTrustResourceUrl(survey.urlImage)" alt="Survey Image" loading="lazy"/>
                </div>
                <div class="name-wrapper" (click)="selectSurveyItem(survey)" >
                  <span>
                    {{survey?.customName? survey?.customName : survey?.name }}
                  </span>
                  <span>
                    {{utils.formatDateToString(survey?.publicationDate)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #emptySurveyList>
          <p>No surveys present</p>
        </ng-template>

      </div>
      <div class="all-survey-wrapper" *ngIf="(authState$ | async); let authState;">
        <div class="top-content">
          <span class="title">
            All surveys
          </span>
          <div  class="filters">
            <div class="filter">
              <span class="name">
                Order by
              </span>
              <div class="base--dropdown-generic">
                <div class="dropbtn">
                  <span>
                    {{getLabelOrder(paginationSurveysOrder, 'survey')}}
                  </span>
                  <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.97119 1L2.98558 3L0.999968 1" stroke="#374957" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="dropdown-content">
                  <a (click)="paginationSurveysOrder=['name','ASC']; getSurveyList();">A-Z</a>
                  <a (click)="paginationSurveysOrder=['name','DESC']; getSurveyList();">Z-A</a>
                  <a (click)="paginationSurveysOrder=['createdAt','DESC']; getSurveyList();">last surveys</a>
                </div>
              </div>
            </div>

          </div>
        </div>


        <table *ngIf="currentSurveyList && currentSurveyList.length > 0;"class="base--table-generic">
          <tr >
            <th>
              Survey
            </th>
            <th >
              Name
            </th>
            <th >
              Publication date
            </th>
            <th >
              Description
            </th>
            <th *ngIf="(authState?.isAuthenticated) &&(authState?.user?.role === 'admin')"></th>
            <th *ngIf="(authState?.isAuthenticated) &&(authState?.user?.role === 'admin')"></th>
          </tr>
          <tr *ngFor="let survey of currentSurveyList| paginate: { itemsPerPage: paginationSurveysLimit, currentPage: pageNumber }; let i = index" (click)="selectSurveyItem(survey)" [ngClass]="[(survey?.isSelected)? 'selected' : '']">
            <td>
              {{survey?.id}}
              <img [src]="this.sanitizer.bypassSecurityTrustResourceUrl(survey.urlImage)" alt="Survey Image" loading="lazy"/>
            </td>
            <td >
              {{survey?.customName? survey?.customName : survey?.name }}
            </td>
            <td>
              {{utils.formatDateToString(survey?.publicationDate)}}
            </td>
            <td>
              {{survey?.description}}
            </td>
            <td *ngIf="(authState?.isAuthenticated) &&(authState?.user?.role === 'admin')">
              <div class="icon" (click)="$event.stopPropagation(); onClickEditSurvey(survey);">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                </svg>
              </div>
            </td>
            <td *ngIf="(authState?.isAuthenticated) &&(authState?.user?.role === 'admin')">
              <div class="icon" (click)="$event.stopPropagation(); onClickOpenModalDeleteSurvey(survey);">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                </svg>
              </div>
            </td>
          </tr>
        </table>
        <div>
          <pagination-controls style="position:relative;" (pageChange)="pageNumber = $event"></pagination-controls>
        </div>

        <ng-container *ngIf="surveyState.surveyList.length === 0;">
          <p>No surveys present</p>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="navbarState.selectContentType === 'ecommerce'">
      <div class="last-survey-wrapper" >
        <div class="top-content">
          <span class="title">
            The last e-commerce modules published
          </span>
          <div class="show-others" (click)="paginationLastEcommerceLimit = paginationLastEcommerceLimit + 4; getLastEcommerceList ();">
            <span>Show others</span>
            <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.5 1L3.5 7M6 4.375L3.5 7L1 4.375" stroke="#A8AAB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
        </div>

        <div *ngIf="lastEcommerceList && lastEcommerceList.length > 0; else emptyEcommerceList">
          <div class="survey-list">
            <div class="survey-item" *ngFor="let ecommerce of lastEcommerceList; let i = index">
              <div class="survey-content">
                <div class="image-wrapper" (click)="selectEcommerceItem(ecommerce)">
                  <img [src]="this.sanitizer.bypassSecurityTrustResourceUrl(ecommerce.coverDesktop)" alt="Ecommerce Image" loading="lazy"/>
                </div>
                <div class="name-wrapper" (click)="selectEcommerceItem(ecommerce)" >
                  <span>
                    {{ecommerce.userProject}}
                  </span>
                  <span>
                    {{utils.formatDateToString(ecommerce?.publicationDate)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #emptyEcommerceList>
          <p>No e-commerce modules present</p>
        </ng-template>

      </div>
      <div class="all-survey-wrapper" *ngIf="(authState$ | async); let authState;">
        <div class="top-content">
          <span class="title">
            All e-commerce modules
          </span>
          <div  class="filters">
            <div class="filter">
              <span class="name">
                Order by
              </span>
              <div class="base--dropdown-generic">
                <div class="dropbtn">
                  <span>
                    {{getLabelOrder(paginationEcommerceOrder, 'ecommerce')}}
                  </span>
                  <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.97119 1L2.98558 3L0.999968 1" stroke="#374957" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="dropdown-content">
                  <a (click)="paginationEcommerceOrder=['user_proj','ASC']; getEcommerceList();">A-Z</a>
                  <a (click)="paginationEcommerceOrder=['user_proj','DESC']; getEcommerceList();">Z-A</a>
                  <a (click)="paginationEcommerceOrder=['createdAt','DESC']; getEcommerceList();">last e-commerce modules</a>
                </div>
              </div>
            </div>

          </div>
        </div>


        <table *ngIf="currentEcommerceList && currentEcommerceList.length > 0;"class="base--table-generic">
          <tr >
            <th>
              Module
            </th>
            <th >
              Name
            </th>
            <th >
              Publication date
            </th>
            <th >
              Platform
            </th>
            <th>
              Clicks
            </th>
            <th></th>
          </tr>
          <tr *ngFor="let ecommerce of currentEcommerceList| paginate: { itemsPerPage: paginationEcommerceLimit, currentPage: pageNumber }; let i = index" (click)="selectEcommerceItem(ecommerce)">
            <td>
              {{ecommerce?.configId}}
              <img [src]="this.sanitizer.bypassSecurityTrustResourceUrl(ecommerce.coverDesktop)" alt="Ecommerce Image" loading="lazy"/>
            </td>
            <td >
              {{ecommerce.userProject }}
            </td>
            <td>
              {{utils.formatDateToString(ecommerce?.publicationDate)}}
            </td>
            <td>
              {{ecommerce.ecommerceType}}
            </td>
            <td>
              {{ecommerce.stat}}
            </td>
            <td *ngIf="(authState?.isAuthenticated) &&(authState?.user?.role === 'admin')">
              <div class="icon" (click)="$event.stopPropagation(); onClickOpenModalDeleteEcommerce(ecommerce);">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                </svg>
              </div>
            </td>
          </tr>
        </table>
        <div>
          <pagination-controls style="position:relative;" (pageChange)="pageNumber = $event"></pagination-controls>
        </div>

        <ng-container *ngIf="currentEcommerceList && currentEcommerceList.length === 0;">
          <p>No e-commerce modules present</p>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="showDeleteSurveyConfirmModal">
  <div class="confirm-delete-modal">
    <div class="modal-content">
      <p>Do you really want to delete the survey <strong>{{ deleteSurveyCandidate?.customName }}</strong>?<br>
      This action is irreversible.</p>
      <div class="actions">
        <button (click)="dismissDeleteSurvey()">Cancel</button>
        <button (click)="confirmDeleteSurvey()" class="bg-azure">Confirm</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showDeleteEcommerceConfirmModal">
  <div class="confirm-delete-modal">
    <div class="modal-content">
      <p>Do you really want to delete the module <strong>{{ deleteEcommerceCandidate?.userProject }}</strong>?<br>
      This action is irreversible.</p>
      <div class="actions">
        <button (click)="dismissDeleteEcommerce()">Cancel</button>
        <button (click)="confirmDeleteEcommerce()" class="bg-azure">Confirm</button>
      </div>
    </div>
  </div>
</ng-container>
