<div id="dash-polarization-wrapper">
  <div class="filter-question-wrapper">
    <form class="base--form-generic" [formGroup]="filterPolarizationForm">
      <div class="filters-wrapper">
        <div class="form-group filter-container" *ngFor="let item  of selectedFiltersListForm.controls; let i = index">
          <div class="form-row">
          <div class="form-column">
            <span class="filter-by"></span>
            <div class="select-wrapper"
              [tippy] = "getLabelQuestionById(item.value.filterQuestionId)"
              [lazy]="true"
              >
              <select class="slim readonly"
                [attr.disabled]="'disabled'"
                [value]="item.value.filterQuestionId"
              >
                <option *ngFor="let question of filterQuestions" [ngValue]="question.id" [selected]="question.id === item.value.filterQuestionId">
                  {{ question.description }}
                </option>
              </select>

            </div>
            <div class="select-image-wrapper">
              <div class="image-dropdown"  >
                <div class="select-image slim readonly" >
                  <ng-container *ngIf="item.value.filterAnswerRangeStart !== null && item.value.filterAnswerRangeEnd !== null; else textImgFilter">
                    {{ item.value.filterAnswerRangeStart + '-' + item.value.filterAnswerRangeEnd }}
                  </ng-container>
                  <ng-template #textImgFilter>
                    {{filterAnswerValue ? getLabelAnswerById(item?.value?.filterQuestionId, item?.value?.filterAnswerId) : 'Select answer'}}
                  </ng-template>
                </div>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>


        <div class="form-group " *ngIf="selectedFiltersListForm.value.length<3 && showFiltersListFormVisible">
          <div class="form-row">
            <div class="form-column">
              <span class="filter-by">Filter by:</span>
              <div class="select-wrapper">
                <select class="slim" formControlName="filterQuestionValue" (change)="onChangeSelectQuestion()">
                  <option value="" selected disabled hidden>Question</option>
                  <option *ngFor="let question of filterQuestions" [ngValue]="question.id">
                    {{ question.description }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="select-image-wrapper">
                <div class="image-dropdown" *ngIf="(filterQuestionValue?.valid)" >
                  <div class="select-image slim" (click)="showOptionsContainer = !showOptionsContainer">
                    {{filterAnswerValue? getLabelAnswerById(filterQuestionValue?.value, filterAnswerValue.value ) : ' Select answer'}}
                  </div>
                  <div class="select-icon slim">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
                <div class="options-container" *ngIf="showOptionsContainer" [ngClass]="showOptionsContainer?'expanded':''">
                  <ng-container  *ngIf="filteredQuestionType === 'knob' || filteredQuestionType === 'slider'; else answerListWrapper">
                    <form class="answers-range-wrapper" [formGroup]="filterPolarizationForm">
                      <div class="answers-range">
                        <div class="interval-value">
                          Inizio intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeStart">
                        </div>
                        <div class="interval-value">
                          Fine intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeEnd">
                        </div>
                      </div>
                      <br>Minimo: {{ answersOptions[0].range_start }}
                      <br>Massimo: {{ answersOptions[0].range_end }}
                    </form>
                  </ng-container>
                  <ng-template #answerListWrapper>
                    <div class="answer-list-wrapper">
                      <div class="answer-list">
                        <div class="answer" [ngClass]="(filterAnswerValue?.value===(answer.id.toString()))?'selected': filterAnswerValue?.value !== '' ?'not-selected':''"  *ngFor="let answer of answersOptions" (click)="setAnswerOption(answer.id.toString())">
                          <div *ngIf="answer.urlImage; else textWrapper" class="image-wrapper" [tippy] = "answer.description">
                            <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.urlImage " alt="Answer Image" loading="lazy"/>
                          </div>
                          <ng-template #textWrapper>
                            <div class="description">
                              {{ answer.description }}
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="answer-controls">
                    <div (click)="onClickCloseOptionsContainer()" class="btn-cancel"> cancel </div>
                    <button (click)="onClickAddNewFilter()" *ngIf="selectedFiltersListForm.value.length<3" [disabled]="!validNewFilter(filteredQuestionType, answersOptions[0].range_start, answersOptions[0].range_end)" type="button" class="btn-ok" > Ok </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="error" *ngIf="filterQuestionValue?.invalid && (filterQuestionValue?.dirty ||filterQuestionValue?.touched)">
            <sup>*</sup>Please provide filter question
          </div>
        </div>
        <div class="form-group justify-content-end">
          <div class="form-row">
            <div class="form-column">
              <div class="btn-default slim"   (click)="onClickShowFiltersListForm()" *ngIf="selectedFiltersListForm.value.length<3 && selectedFiltersListForm.value.length>=1">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_509_2721)">
                    <path d="M9.65117 11.312C9.33584 11.312 9.09217 11.226 8.92017 11.054C8.74817 10.8677 8.66217 10.6168 8.66217 10.3015V6.7755H5.20067C4.89967 6.7755 4.66317 6.69667 4.49117 6.539C4.31917 6.367 4.23317 6.1305 4.23317 5.8295C4.23317 5.5285 4.31917 5.29917 4.49117 5.1415C4.66317 4.9695 4.89967 4.8835 5.20067 4.8835H8.66217V1.465C8.66217 1.14967 8.74817 0.905999 8.92017 0.733999C9.09217 0.561999 9.34301 0.476 9.67267 0.476C9.98801 0.476 10.2245 0.561999 10.3822 0.733999C10.5542 0.905999 10.6402 1.14967 10.6402 1.465V4.8835H14.1017C14.417 4.8835 14.6535 4.9695 14.8112 5.1415C14.9832 5.29917 15.0692 5.5285 15.0692 5.8295C15.0692 6.1305 14.9832 6.367 14.8112 6.539C14.6535 6.69667 14.417 6.7755 14.1017 6.7755H10.6402V10.3015C10.6402 10.6168 10.5542 10.8677 10.3822 11.054C10.2245 11.226 9.98084 11.312 9.65117 11.312Z" fill="white" />
                  </g>
                  <defs>
                    <filter id="filter0_d_509_2721" x="0.233154" y="0.476074" width="18.8359" height="18.8359" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_2721" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_2721" result="shape" />
                    </filter>
                  </defs>
                </svg> Add filter
              </div>
              <div class="btn-default slim" (click)="onClickRemoveLastFilter()" *ngIf="selectedFiltersListForm.value.length>0" >
                cancel
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-filters-wrapper" *ngIf="!isOnePeriod">
        <div class="form-group">
          <div class="form-row">
            <div class="form-column">
              <span class="filter-by">Choose Period:</span>
              <div class="select-wrapper">
                <select class="slim" formControlName="filterSelectedPeriodValue" (change)="onChangeSelectPeriod()" value="0">
                  <option value="" selected disabled hidden>Period 1</option>
                  <option value="0" >Period 1</option>
                  <option value="1" >Period 2</option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="base--tpanel-periods-wrapper">
        <div class="period pointer" [ngClass]="{'purple': filterSelectedPeriodValue?.value === '0' || filterSelectedPeriodValue?.value === ''}" >
          <div class="left-col-wrapper">
            <div class="icon">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
              </svg>
            </div>
            <div class="info-wrapper">
              <div class="title">
                  PERIOD {{ isOnePeriod ? '' : '1' }}
              </div>
              <div class="dates-wrapper">
                <span>{{utils.formatDateToString(periodOneStartDate)}}</span>
                <span>{{utils.formatDateToString(periodOneEndDate)}}</span>
              </div>
            </div>
          </div>
          <div class="right-col-wrapper">
            <div class="top-wrapper">
              <span>Total <br> respondents</span>
            </div>
            <div class="bottom-wrapper">
              <span>{{this.periodsCounters.completed[0]}}</span>
            </div>
          </div>
        </div>
        <div class="period pointer" [ngClass]="{'azure': filterSelectedPeriodValue?.value === '1'}" *ngIf="!isOnePeriod">
          <div class="left-col-wrapper">
            <div class="icon">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
              </svg>
            </div>
            <div class="info-wrapper">
              <div class="title">
                  PERIOD 2
              </div>
              <div class="dates-wrapper">
                <span>{{utils.formatDateToString(periodTwoStartDate)}}</span>
                <span>{{utils.formatDateToString(periodTwoEndDate)}}</span>
              </div>
            </div>
          </div>
          <div class="right-col-wrapper">
            <div class="top-wrapper">
              <span>Total <br> respondents</span>
            </div>
            <div class="bottom-wrapper">
              <span>{{this.periodsCounters.completed[1]}}</span>
            </div>
          </div>
        </div>
        <div class="export-wrapper" (click)="onClickDownloadImage()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17C3 16.7348 3.10536 16.4804 3.29289 16.2929C3.48043 16.1054 3.73478 16 4 16H16C16.2652 16 16.5196 16.1054 16.7071 16.2929C16.8946 16.4804 17 16.7348 17 17C17 17.2652 16.8946 17.5196 16.7071 17.7071C16.5196 17.8946 16.2652 18 16 18H4C3.73478 18 3.48043 17.8946 3.29289 17.7071C3.10536 17.5196 3 17.2652 3 17ZM6.293 9.293C6.48053 9.10553 6.73484 9.00021 7 9.00021C7.26516 9.00021 7.51947 9.10553 7.707 9.293L9 10.586V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V10.586L12.293 9.293C12.3852 9.19749 12.4956 9.12131 12.6176 9.0689C12.7396 9.01649 12.8708 8.9889 13.0036 8.98775C13.1364 8.9866 13.2681 9.0119 13.391 9.06218C13.5139 9.11246 13.6255 9.18671 13.7194 9.28061C13.8133 9.3745 13.8875 9.48615 13.9378 9.60905C13.9881 9.73194 14.0134 9.86362 14.0123 9.9964C14.0111 10.1292 13.9835 10.2604 13.9311 10.3824C13.8787 10.5044 13.8025 10.6148 13.707 10.707L10.707 13.707C10.5195 13.8945 10.2652 13.9998 10 13.9998C9.73484 13.9998 9.48053 13.8945 9.293 13.707L6.293 10.707C6.10553 10.5195 6.00021 10.2652 6.00021 10C6.00021 9.73484 6.10553 9.48053 6.293 9.293V9.293Z" fill="#533B83"/>
          </svg>
          <span>
            Export
          </span>
        </div>
      </div>
      <div class="row p-0">
        <div class="polarization-chart-wrapper col-12">
          <table>
            <tr>
              <th>

              </th>
              <th *ngFor="let header of this.polarizationAnswers$?.headersPolarization">
                <div [tippy]="header.text">D_{{ header.id }}</div>
              </th>
            </tr>

            <tr *ngFor="let indexAnswers of this.polarizationAnswers$?.answersFilled; let i = index">
              <td> {{ indexAnswers.score }}</td>
              <ng-container *ngFor="let answer of indexAnswers.answers; let i = index">
                <td >
                <ng-container *ngIf="(answer.risposta !== null && answer.risposta !== undefined); else elseNullImage">
                  <ng-container *ngIf="answer.risposta.includes('png') || answer.risposta.includes('jpg') || answer.risposta.includes('jpeg'); else answerText">
                    <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.risposta" loading="lazy" [tippy]="answer.testo_risposta"/>
                  </ng-container>
                  <ng-template #answerText>
                    <ng-container [ngSwitch]="answer.risposta">
                      <ng-container *ngSwitchCase="'high-range'">
                        <div class="img-wrapper green" [ngClass]="{'round': answer.question_type === 'knob'}">
                          <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.image_path" loading="lazy"/>
                          <img *ngIf="answer.question_type === 'slider'" [src]="'https://epathoscdn.it/TEST/img/'+ answer.image_path_2" loading="lazy"/>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="['medium-range', 'mid-range'].includes(answer.risposta) ? answer.risposta : ''">
                        <div class="img-wrapper orange" [ngClass]="{'round': answer.question_type === 'knob'}">
                          <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.image_path" loading="lazy"/>
                          <img *ngIf="answer.question_type === 'slider'" [src]="'https://epathoscdn.it/TEST/img/'+ answer.image_path_2" loading="lazy"/>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'low-range'">
                        <div class="img-wrapper red" [ngClass]="{'round': answer.question_type === 'knob'}">
                          <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.image_path" loading="lazy"/>
                          <img *ngIf="answer.question_type === 'slider'" [src]="'https://epathoscdn.it/TEST/img/'+ answer.image_path_2" loading="lazy"/>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <div class="text">{{ answer.risposta }}</div>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </ng-container>
                <ng-template #elseNullImage><div>
                  <div class="empty-image-box">
                  </div>
                </div>
                </ng-template>
                </td>
              </ng-container>
            </tr>
          </table>

        </div>
      </div>

    </form>
  </div>
</div>
