//Angular
import { Component, OnInit, SimpleChange, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router  } from '@angular/router';

//Models
import { Period } from "src/app/models/Period";
import { Answer, AnswerRange } from "src/app/models/Answer";
import { Question } from 'src/app/models/Question';

//dependecies
import { ToastrService } from "ngx-toastr";

//Store
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

//States
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";
import { SurveyState } from '../../../../stores/survey/survey.reducer';

//services
import { DashboardService } from '../../../../services/dashboard/dashboard.service';

//Request
import { AggregationRequest } from "src/app/models/global.request";


//Utils
import  Utils  from 'src/app/utils/utils';
import  Formatter  from 'src/app/utils/formatters';

//Htmlto image
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

//Echarts
import { EChartsOption } from 'echarts';

@Component({
  selector: "app-dash-aggregation",
  templateUrl: "./dash-aggregation.component.html",
  styleUrls: ["./dash-aggregation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardAggregationComponent  implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  onClickOpenModalAggregationKeys() {
    throw new Error('Method not implemented.');
  }
  sidebarState$: Observable<SidebarState>;
  surveyState$ : Observable<SurveyState>;
  utils!:Utils;
  formatter!:Formatter;

  dashboardData$: Observable<any[]> | undefined;
  answersOptions !: AnswerRange[];
  filteredQuestionType = "";

  //Local Variables
  periodList!: Period[] | null;
  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;
  isOnePeriod = false;

  surveyName!: string;
  filterQuestions: any ;
  periodsCounters : any;
  currentSurvey: any ;
  polarizationAnswers$!: any;
  selectedTouchPoint: any;
  domandaId: any;
  touchpointsGroupped: any;
  questions: any;

  //Toggle
  showOptionsContainer !: boolean;
  showFiltersListFormVisible: boolean = true;
  selectedCrossQuestion!: Question;
  currentPeriodToggle!: number;
  showModalKeys: boolean = false;

  //Pagination
  startPage : Number;
  paginationLimit:Number;

  touchPoints: any = [
    {
      id:  1,
      description:"Test1"
    },
    {
      id:  2,
      description:"Test2"
    }
  ]
  chartOption!: EChartsOption;
  aggregationData$: any;

  //Constructor
  constructor(
    private store: Store<{ sidebarState: SidebarState, surveyState: SurveyState  }>,
    public fb: FormBuilder,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private router: Router

  ) {
    this.sidebarState$ = store.select("sidebarState");
    this.surveyState$ = store.select('surveyState');
    this.utils = new Utils();
    this.formatter = new Formatter();
    this.startPage = 0;
    this.paginationLimit = 10;
    this.currentPeriodToggle = 0;

  }
  filterAggregationForm = this.fb.group({
    filterQuestionValue:  [ "", [Validators.required]],
    filterQuestionType:  [ "", [Validators.required]],
    filterAnswerValue: ["", [Validators.required]],
    filterAnswerRangeStart: ["", [Validators.required]],
    filterAnswerRangeEnd: ["", [Validators.required]],
    filterTouchPointValue: ["", [Validators.required]],
    selectedFiltersListForm: this.fb.array([],) ,
  });


  ngOnInit(): void {

    this.surveyState$.subscribe(surveyS => {
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if( period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
        }
        if( period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
        }
      });
      if (this.periodOneStartDate.toISOString() === this.periodTwoStartDate.toISOString() && this.periodOneEndDate.toISOString() === this.periodTwoEndDate.toISOString()) {
        this.isOnePeriod = true;
      }

      const nameSurvey = surveyS?.currentSurvey?.customName ? surveyS?.currentSurvey?.customName : surveyS?.currentSurvey?.name;
      this.surveyName = nameSurvey || "";
      this.currentSurvey = surveyS.currentSurvey
      this.filterQuestions = surveyS.filterQuestions;
      this.periodsCounters = surveyS.periodsCounters;
      this.touchpointsGroupped = this.formatTouchPoints(surveyS.touchpoints);
      this.questions = surveyS.questions;
    });

    this.loadChartsData();
  }
  onChangeSelectQuestion() {
    if(this.filterQuestionValue?.value) {
      const selectedIndexQuestionId = parseInt(this.filterQuestionValue?.value, 10);
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id === selectedIndexQuestionId));
      this.answersOptions = tempSelectedQuestion.answers;
      this.domandaId = tempSelectedQuestion.domanda_id;
      this.filteredQuestionType = tempSelectedQuestion.type;
      this.filterQuestionType?.patchValue(tempSelectedQuestion.type);
    }

    // this.setFilterAnswerValue("");
  }

  onChangeSelectTouchPoint() {
    if(this.filterTouchPointValue?.value) {
      const touchPointId = parseInt(this.filterTouchPointValue?.value, 10 );
      this.selectedTouchPoint = this.touchPoints.find(((tTouchPoint : any)  => tTouchPoint.id == touchPointId));

      this.loadChartsData();
    }

  }

  setAnswerOption(idAnswer: string) {
    this.filterAggregationForm.controls['filterAnswerValue'].setValue(idAnswer);
  }

  onClickAddNewFilter() {
    const filterQuestionValue = this.filterQuestionValue?.value;
    const filterQuestionType = this.filterQuestionType?.value;
    const filterAnswerValue = this.filterAnswerValue?.value;
    const filterAnswerRangeStart = this.filterAnswerRangeStart?.value;
    const filterAnswerRangeEnd = this.filterAnswerRangeEnd?.value;

    if(filterQuestionValue && filterAnswerValue) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerValue});
    } else if (filterQuestionValue && filterAnswerRangeStart !== null && filterAnswerRangeEnd !== null) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerRangeStart, filterAnswerRangeEnd});
    }

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();

    this.showOptionsContainer = false;
    this.showFiltersListFormVisible = false;
    this.loadChartsData();
  }


  onClickCloseOptionsContainer() {
    this.showOptionsContainer = false;

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();
  }

  onClickShowFiltersListForm () {
    this.showFiltersListFormVisible = true;

  }
  onClickChangePeriod (periodNum : number) {
    this.currentPeriodToggle = periodNum;
    this.paginationLimit = 10;
    this.loadChartsData();

  }
  onClickDownloadImage() {
    const node = document.getElementById('dash-aggregation-wrapper') as HTMLElement;
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'dash-aggregation-image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }
  onClickRemoveLastFilter() {
    if(this.selectedFiltersListForm.value.length == 1) {
      this.showFiltersListFormVisible = true;
    }
    const lastIndex = (this.selectedFiltersListForm.value.length) -1;
    this.selectedFiltersListForm.removeAt(lastIndex);

    if (lastIndex > 0) {
      this.filterQuestionValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionId);
      this.filterQuestionType?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionType);
      this.filterAnswerValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeStart?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeEnd?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
    } else {
      this.filterQuestionValue?.patchValue('');
      this.filterQuestionType?.patchValue('');
      this.filterAnswerValue?.patchValue('');
      this.filterAnswerRangeStart?.patchValue('');
      this.filterAnswerRangeEnd?.patchValue('');
      this.filterQuestionValue?.markAsPristine();
      this.filterQuestionValue?.markAsUntouched();
      this.filterQuestionType?.markAsPristine();
      this.filterQuestionType?.markAsUntouched();
      this.filterAnswerValue?.markAsPristine();
      this.filterAnswerValue?.markAsUntouched();
      this.filterAnswerRangeStart?.markAsPristine();
      this.filterAnswerRangeStart?.markAsUntouched();
      this.filterAnswerRangeEnd?.markAsPristine();
      this.filterAnswerRangeEnd?.markAsUntouched();
    }

    this.loadChartsData();
  }

  addSelectedFilterList(filter: any) {
    this.selectedFiltersListForm.push(this.newFilter(filter));
  }

  newFilter(filter: any): FormGroup {
    return this.fb.group({
      filterQuestionId:  filter.filterQuestionValue,
      filterQuestionType:  filter.filterQuestionType,
      filterAnswerId: filter.filterAnswerValue,
      filterAnswerRangeStart: filter.filterAnswerRangeStart,
      filterAnswerRangeEnd: filter.filterAnswerRangeEnd,
    })
  }


  setfilterQuestionValue(value: any) {
    this.filterQuestionValue?.setValue(value, {
      onlySelf: true,
    });
  }
  setfilterQuestionType(value: any) {
    this.filterQuestionType?.setValue(value, {
      onlySelf: true,
    });
  }

  setFilterAnswerValue(value: any) {
    this.filterAnswerValue?.setValue(value, {
      onlySelf: true,
    });
  }

  setFilterRangeStart(value: any) {
    this.filterAnswerRangeStart?.setValue(value, {
      onlySelf: true,
    });
  }

  setFilterRangeEnd(value: any) {
    this.filterAnswerRangeEnd?.setValue(value, {
      onlySelf: true,
    });
  }

  setfilterTouchPointValue(value: any) {
    this.filterTouchPointValue?.setValue(value, {
      onlySelf: true,
    });
  }


  get filterQuestionValue() {
    return this.filterAggregationForm.get("filterQuestionValue");
  }
  get filterQuestionType() {
    return this.filterAggregationForm.get("filterQuestionType");
  }
  get filterAnswerValue() {
    return this.filterAggregationForm.get("filterAnswerValue");
  }
  get filterAnswerRangeStart() {
    return this.filterAggregationForm.get("filterAnswerRangeStart");
  }

  get filterAnswerRangeEnd() {
    return this.filterAggregationForm.get("filterAnswerRangeEnd");
  }
  get filterTouchPointValue() {
    return this.filterAggregationForm.get("filterTouchPointValue");
  }
  get selectedFiltersListForm() : FormArray {
    return this.filterAggregationForm.get("selectedFiltersListForm") as FormArray
  }

  getLabelQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.description
      }
    }

    return " "
  }

  getLabelQuestionsById(questionId: any) {
    if(questionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == questionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.description
      }
    }

    return " "
  }
  getLabelAnswerById(filterQuestionId: any, filterAnswerId: any, allowEmpty: boolean = false) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.description
        }
      }
    }

    if (allowEmpty) {
      return "";
    }

    return "Select answer"
  }
  getDomandaIdQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.domanda_id
      }
    }

    return " "
  }
  getImageAnswerById(filterQuestionId: any, filterAnswerId: any) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.urlImage
        }
      }
    }

    return null
  }
  getFormatedFilters () {
    const formattedFilters: any = [];

    this.selectedFiltersListForm.controls.forEach( (filterElement) =>{
      const domandaId = this.getDomandaIdQuestionById(filterElement.value.filterQuestionId);
      const imagePath = this.getImageAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId);
      const description = this.getLabelAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId, true);
      const interval_start = filterElement.value.filterAnswerRangeStart;
      const interval_end = filterElement.value.filterAnswerRangeEnd;
      const question_type = filterElement.value.filterQuestionType;
      formattedFilters.push({question_id: filterElement.value.filterQuestionId, domanda_id: domandaId, answer_image: imagePath, description: description, interval_start: interval_start, interval_end: interval_end, question_type: question_type })
    });

    return formattedFilters;
  }

  formatTouchPoints (data: any) {
    const touchpointsData: any[] = [];
    for (const key in data) {
      const questionsTemp = data[key];
      const questions = []
      for (let index = 0; index < questionsTemp.length; index++) {
          questions.push({
            domanda_id: questionsTemp[index].domanda_id,
            question_id: questionsTemp[index].question_id,
            description: questionsTemp[index].description,
          });
      }
      touchpointsData.push({
        group: key,
        questions: questions,
        rowspan: questions.length,
      })
    }

    return touchpointsData;

  }

  showMoreItems()
  {
    this.paginationLimit = Number(this.paginationLimit) + 10;
    this.loadChartsData();
  }
  showLessItems()
  {
    this.paginationLimit = Number(this.paginationLimit) - 10;
  }
  onSave() {
    this.closebutton.nativeElement.click();
  }
  toggleModalKeys() {
    this.showModalKeys = !this.showModalKeys;
  }
  loadChartsData() {
    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }
    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }

    const request: AggregationRequest = {
      survey_id: this.currentSurvey?.id,
      survey_name: this.currentSurvey?.name,
      periods:[periodOne, periodTwo],
      filterQuestions: this.getFormatedFilters() || [],
    }
    this.dashboardService.getAggregationCharts(request).subscribe((data) =>
    {
      this.aggregationData$ = this.formatter.formatResponseAnalysisByAggregation(data);
      if (this.isOnePeriod) {
        this.chartOption = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },

          legend: {
            data: ['Period']
          },
          xAxis: {
            type: 'category',
            data: this.aggregationData$.touchpoints
          },
          yAxis: {
            type: 'value',
            min: 1,
            max: 3,
          },
          series: [
            {
              name:'Period',
              data: this.aggregationData$.period1Values,
              type: 'line',
              lineStyle: {color: '#533B83'},
              itemStyle: {color: '#533B83'}

            }
          ]
        };
      } else {
        this.chartOption = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },

          legend: {
            data: ['Period 1', 'Period 2']
          },
          xAxis: {
            type: 'category',
            data: this.aggregationData$.touchpoints
          },
          yAxis: {
            type: 'value',
            min: 1,
            max: 3,
          },
          series: [
            {
              name:'Period 1',
              data: this.aggregationData$.period1Values,
              type: 'line',
              lineStyle: {color: '#533B83'},
              itemStyle: {color: '#533B83'}

            },
            {
              name:'Period 2',
              data: this.aggregationData$.period2Values,
              type: 'line',
              lineStyle: {color: '#7FCFC2'},
              itemStyle: {color: '#7FCFC2'}

            }
          ]
        };
      }
    });

  }

  validNewFilter(type: string, range_start: number, range_end: number) {
    if (type === 'knob' || type === 'slider') {
      return this.filterAnswerRangeStart?.valid &&
        this.filterAnswerRangeEnd?.valid &&
        this.filterAnswerRangeStart?.value !== null &&
        this.filterAnswerRangeEnd?.value !== null &&
        parseInt(this.filterAnswerRangeStart?.value) < parseInt(this.filterAnswerRangeEnd?.value) &&
        parseInt(this.filterAnswerRangeStart?.value) >= range_start &&
        parseInt(this.filterAnswerRangeStart?.value) <= range_end &&
        parseInt(this.filterAnswerRangeEnd?.value) >= range_start &&
        parseInt(this.filterAnswerRangeEnd?.value) <= range_end;
    } else {
      return this.filterAnswerValue?.valid;
    }
  }
}
