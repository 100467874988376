import {
  FilteredQuestionRequest,
  QuestionsOverviewChartsRequest,
  QuestionsOverviewCrossTabChartsRequest,
  LandingChartsRequest,
  SatisfactionChartsRequest,
  NPSChartsRequest,
  PolarizationRequest,
  OpenTextBoxesRequest,
  AggregationRequest,
  TextAreaIdRequest,
  EmotionalRequest
} from './../../models/global.request';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { Customer } from '../models/customer';


@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  private serviceContainer = "dashboard";
  constructor(private http: HttpClient) { }

  public getFilteredQuestions(req: FilteredQuestionRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/get-filtered-questions`, req);
  }
  public getLandingCharts(req: LandingChartsRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/landing`, req);
  }
  public getQuestionsOverviewCharts(req: QuestionsOverviewChartsRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/questions-overview`, req);
  }
  public getQuestionsOverviewCrossTabCharts(req: QuestionsOverviewCrossTabChartsRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/cross-tabulation`, req);
  }

  public getSatisfactionCharts(req: SatisfactionChartsRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/satisfaction`, req);
  }
  public getNPSCharts(req: NPSChartsRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/nps`, req);
  }
  public getPolarizationCharts(req: PolarizationRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/polarization`, req);
  }
  public getOpenTextBoxesCharts(req: OpenTextBoxesRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/open-text-boxes`, req);
  }
  public getAggregationCharts(req: AggregationRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/analysis-by-aggregation`, req);
  }
  public getEmotionalCharts(req: EmotionalRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/overview`, req);
  }

  public getTextAreaId(req: TextAreaIdRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}/get-text-area-id`, req);
  }
}
