import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SurveyRoutingModule } from './dashboard-routing.module';


import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';

// import { AuthenticationService } from '../@core/services/auth/authentication.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';


//Tooltip

import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';


//Material UI
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import {OverlayModule} from '@angular/cdk/overlay';

//Services
import { DashboardService } from '../../services/dashboard/dashboard.service';

import { DashboardLandingComponent } from './components/dash-landing/dash-landing.component';
import { DashboardQuestionsOverviewComponent } from './components/dash-questions-overview/dash-questions-overview.component';
import { DashboardSatisfactionComponent } from './components/dash-satisfaction/dash-satisfaction.component';
import { DashboardPolarizationComponent } from './components/dash-polarization/dash-polarization.component';
import { DashboardNPSComponent } from './components/dash-nps/dash-nps.component';
import { DashboardAggregationComponent } from './components/dash-aggregation/dash-aggregation.component';
import { DashboardTextBoxesComponent } from './components/dash-text-boxes/dash-text-boxes.component';

//Echarts
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { TagCloudComponent } from 'angular-tag-cloud-module';
import { DashboardEmotionalOverviewComponent } from './components/dash-emotional-overview/dash-emotional-overview.component';


@NgModule({
  declarations: [
    DashboardLandingComponent,
    DashboardQuestionsOverviewComponent,
    DashboardSatisfactionComponent,
    DashboardPolarizationComponent,
    DashboardNPSComponent,
    DashboardAggregationComponent,
    DashboardTextBoxesComponent,
    DashboardEmotionalOverviewComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    SurveyRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatIconModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MomentModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    NgxEchartsModule.forRoot({
      echarts
    }),
    TagCloudComponent,
  ],
  providers: [
    DashboardService
  ],
  exports:[
    DashboardLandingComponent,
    DashboardQuestionsOverviewComponent,
    DashboardSatisfactionComponent,
    DashboardPolarizationComponent,
    DashboardNPSComponent,
    DashboardAggregationComponent,
    DashboardTextBoxesComponent,
    DashboardEmotionalOverviewComponent,
  ]
})
export class DashboardModule { }
